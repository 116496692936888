import React, { useState } from "react";

import LineMsg from "./lineMsg";
import DropdownMenu from "./dropdownMenu";
import RemovableItemWithDropdown from "./removableItemWithDropdown";

import { strings } from "../../util/formatter";

export const CATEGORY = [
    {
        label: "سياسة",
        value: "politics"
    },
    {
        label: "تاريخ",
        value: "history"
    },
    {
        label: "رواية",
        value: "novel"
    },
    {
        label: "ادب",
        value: "literature"
    }
];

export const CATEGORY_TYPE = [
    {
        label: "تصنيف أساسي",
        value: "category"
    },
    {
        label: "تصنيف فرعي",
        value: "subcategory"
    }
];

const AddProductStep3 = () =>
{
    const [category, setCategory] = useState({});
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);
    const [shouldShowErr, setShouldShowErr] = useState(false);

    const addCategory = (item) =>
    {
        setCategory({});
        if (!(selectedCategoryList?.some(category => category.value === item.value)))
        {
            setShouldShowErr(false);
            setSelectedCategoryList([...selectedCategoryList, item]);
        }
        else
        {
            setShouldShowErr(true);
        }
    };

    const removeCategory = (item) =>
    {
        const temp = selectedCategoryList;
        const arr = temp?.filter(category => category.value !== item.value);
        setSelectedCategoryList([...arr]);
    };

    const addCategoryType = (type, item) =>
    {
        const temp = selectedCategoryList;
        const foundItem = temp?.find(category => category.value === item.value);

        if (foundItem)
        {
            foundItem.type = type;
            setSelectedCategoryList([...temp]);
        }
    };

    return (
        <>
            <div className={`w-full pb-grid-sm`}>
                <DropdownMenu
                    list={CATEGORY}
                    labelKey="label"
                    placeholder={strings("placeholder.selectCategory")}
                    selectedValue={category}
                    setSelected={addCategory}
                />
                {shouldShowErr && <LineMsg type="error" msg={strings("message.categoryAlreadyExists")}/>}
            </div>
            {selectedCategoryList?.length > 0 && <div className="h-[1px] mb-grid-sm bg-primary-light"/>}
            <div>
                {selectedCategoryList?.length > 0 &&
                    selectedCategoryList?.map((category, index) =>
                        (
                            <RemovableItemWithDropdown
                                key={index}
                                index={index}
                                item={category}
                                placeholder={strings("placeholder.categoryType")}
                                labelKey={"label"}
                                selected={category?.type?.label ? category?.type : {}}
                                dropdownList={CATEGORY_TYPE}
                                listLength={selectedCategoryList?.length}
                                addValue={(e) => addCategoryType(e, category)}
                                removeItem={() => removeCategory(category)}
                            />
                        ))
                }
            </div>
        </>
    );
};

export default AddProductStep3;
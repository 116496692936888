import React from "react";

import SVGIcon from "./svgIcons";

const BookCard = (props) =>
{
    const { rank, rankStatus, shouldShowRankText = false } = props;
    return (
        <div className="py-2 flex gap-1 justify-center items-center font-primary">
            <div className={`w-[24px] h-[24px] flex justify-center items-center rounded-full ${rankStatus === "up" ? "bg-emerald-500" : rankStatus === "down" ? "bg-red-500" : rankStatus === "star" ? "bg-amber-500" : "bg-gray-500"}`}>
                {rankStatus === "up"
                    ? <SVGIcon name="chevron" viewBox="0 0 64 64" width={17} height={17} stroke="stroke-primary-lightest" className="-rotate-90 fill-none"/>
                    : rankStatus === "down"
                        ? <SVGIcon name="chevron" viewBox="0 0 64 64" width={17} height={17} stroke="stroke-primary-lightest" className="rotate-90 fill-none"/>
                        : rankStatus === "star"
                            ? <SVGIcon name="star" width={16} height={16} stroke="stroke-primary-lightest"/>
                            : <SVGIcon name="minus" width={12} height={4} className={`fill-primary-lightest`}/>
                }
            </div>
            {shouldShowRankText &&
                <p className="text-lg">{rank}</p>
            }
        </div>
    );
};

export default BookCard;
import React, { createRef, useContext, useState } from "react";

import Label from "../view/component/label";
import DropdownMenu from "../view/component/dropdownMenu";
import LineMsg from "../view/component/lineMsg";
import Input from "../view/component/input";
import Button from "../view/component/button";
import FileInput from "../view/component/fileInput";
import Tabs from "../view/component/tabs";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const LANGUAGE = [
    {
        label: "العربية",
        value: "ar"
    },
    {
        label: "الإنجليزية",
        value: "en"
    }
];

const UserAccount = () =>
{
    const { setActiveModal } = useContext(SessionContext);
    const profileImgRef = createRef(null);
    const [active, setActive] = useState("profile");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [profileImg, setProfileImg] = useState({});
    const [language, setLanguage] = useState({});
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const selectProfileImg = () =>
    {
        if (profileImgRef.current !== null)
        {
            profileImgRef.current.click();
        }
    };

    const save = () =>
    {
        setActiveModal(undefined);
    };

    return (
        <>
            <Tabs
                active={active}
                setActive={setActive}
                isModal={true}
                list={[
                    { key: "profile", label: strings("common.profile") },
                    { key: "password", label: strings("common.password") }
                ]}
                shouldShowAll={false}
            >
                <div className="p-grid-md text-secondary-darkest font-secondary">
                    {active === "profile"
                        ? <>
                            <div className="flex justify-between gap-grid-xl">
                                <div className="w-full pb-grid-sm">
                                    <Label label={strings("label.firstName")}/>
                                    <Input
                                        name="firstName"
                                        value={firstName}
                                        setValue={setFirstName}
                                    />
                                    <LineMsg type="error" msg={strings("message.genericError")}/>
                                </div>
                                <div className="w-full pb-grid-sm">
                                    <Label label={strings("label.lastName")}/>
                                    <Input
                                        name="lastName"
                                        value={lastName}
                                        setValue={setLastName}
                                    />
                                    <LineMsg type="error" msg={strings("message.genericError")}/>
                                </div>
                            </div>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.email")}/>
                                <Input
                                    name="email"
                                    value={email}
                                    setValue={setEmail}
                                />
                                <LineMsg type="error" msg={strings("message.genericError")}/>
                            </div>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.profileImage")}/>
                                <FileInput
                                    fileRef={profileImgRef}
                                    value={profileImg}
                                    setValue={setProfileImg}
                                    selectFile={selectProfileImg}
                                />
                                <LineMsg type="error" msg={strings("message.genericError")}/>
                            </div>
                            <div className="w-1/2 pb-grid-sm">
                                <Label label={strings("label.language")}/>
                                <DropdownMenu
                                    list={LANGUAGE}
                                    labelKey="label"
                                    placeholder={strings("placeholder.selectLang")}
                                    selectedValue={language}
                                    setSelected={setLanguage}
                                />
                                <LineMsg type="error" msg={strings("message.genericError")}/>
                            </div>
                        </>
                        : <>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.password")}/>
                                <Input
                                    name="password"
                                    value={password}
                                    type="password"
                                    placeholder={strings("placeholder.password")}
                                    setValue={setPassword}
                                />
                                <LineMsg type="error" msg={strings("message.genericError")}/>
                            </div>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.newPassword")}/>
                                <Input
                                    name="newPassword"
                                    value={newPassword}
                                    type="password"
                                    placeholder={strings("placeholder.password")}
                                    setValue={setNewPassword}
                                />
                                <LineMsg type="error" msg={strings("message.genericError")}/>
                            </div>
                            <div className="w-full pb-grid-sm">
                                <Label label={strings("label.confirmNewPassword")}/>
                                <Input
                                    name="confirmPassword"
                                    value={confirmNewPassword}
                                    type="password"
                                    placeholder={strings("placeholder.password")}
                                    setValue={setConfirmNewPassword}
                                />
                                <LineMsg type="error" msg={strings("message.genericError")}/>
                            </div>
                        </>
                    }
                </div>
            </Tabs>
            <div className="text-end pt-grid-lg px-grid-md pb-grid-md">
                <Button
                    text={strings("button.save")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={save}
                />
            </div>
        </>
    );
};

export default UserAccount;
import React from "react";
import Login from "../controller/login";
import Signup from "../controller/signup";
import ResetPassword from "../controller/resetPassword";
import OTP from "../controller/otp";
import CreateEntity from "../controller/createEntity";
import Subscription from "../controller/subscription";
import ProductModal from "../controller/productModal";
import ContributorModal from "../controller/contributorModal";
import ProducerModal from "../controller/producerModal";
import SellerModal from "../controller/sellerModal";
import AddProductModal from "../controller/addProductModal";
import ShareProduct from "../controller/shareProduct";
import AddOutlet from "../controller/addOutlet";
import UploadSales from "../controller/uploadSales";
import UserAccount from "../controller/userAccount";
import ManageEntity from "../controller/manageEntity";
import DisableEntityAccount from "../controller/disableEntityAccount";

const MODAL = {
    login: <Login/>,
    signup: <Signup/>,
    resetPassword: <ResetPassword/>,
    otp: <OTP/>,
    createEntity: <CreateEntity/>,
    subscription: <Subscription/>,
    product: <ProductModal/>,
    contributor: <ContributorModal/>,
    producer: <ProducerModal/>,
    seller: <SellerModal/>,
    addProduct: <AddProductModal/>,
    shareProduct: <ShareProduct/>,
    addOutlet: <AddOutlet/>,
    uploadSales: <UploadSales />,
    userAccount: <UserAccount />,
    manageEntity: <ManageEntity />,
    disableEntityAccount: <DisableEntityAccount />
};

export default MODAL;
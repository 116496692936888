/* eslint-disable no-constant-condition */
import React, { useContext, useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { HelmetProvider, Helmet } from "react-helmet-async";

import Header from "../component/header";
import Footer from "../component/footer";

import { SessionContext } from "../../util/context";

const Layout = () =>
{
    const { prefs } = useContext(SessionContext);
    const location = useLocation();

    useLayoutEffect(() =>
    {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className="w-full transition-colors duration-middle ease-in font-secondary">
            <HelmetProvider>
                <Helmet>
                    <html lang={prefs?.lang} data-theme={prefs?.theme} dir={prefs?.dir} className="transition-colors duration-middle ease-in"/>
                </Helmet>
                {(window.location.origin === "https://mofahres.com" || window.location.origin === "https://qa.mofahres.com" || window.location.origin === "https://preprod.mofahres.com")
                    ? <div className="w-full h-screen flex justify-center items-center">
                        <p dir="rtl" className="text-xxl font-primary text-primary-lightest">
                            قريبا! ...
                        </p>
                    </div>
                    : <>
                        <Header />
                        <div className="pt-[70px]">
                            <Outlet/>
                        </div>
                        <Footer />
                    </>
                }
            </HelmetProvider>
        </div>
    );
};

export default Layout;
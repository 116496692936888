import React from "react";

import SVGIcon from "./svgIcons";

const DataCardWithIcon = (props) =>
{
    const { icon, iconViewBox, label, value } = props;

    return (
        <div className={`p-grid-sm flex flex-col gap-grid-sm justify-center items-center bg-neutral-high-regular rounded-sm`}>
            <div className="p-grid-sm bg-neutral-high-dark rounded-full">
                <SVGIcon name={icon} width={16} height={16} viewBox={iconViewBox} stroke={"stroke-neutral-low-dark"}/>
            </div>
            <p className="text-md text-neutral-low-dark font-primary">
                {label}
            </p>
            <p className="text-md text-neutral-low-dark">
                {value}
            </p>
        </div>
    );
};

export default DataCardWithIcon;
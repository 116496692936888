import React, { useContext } from "react";

import { SessionContext } from "../../util/context";
import { strings, getDate } from "../../util/formatter";
import SVGIcon from "./svgIcons";

const DateRange = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { dateFrom, dateTo = "0000-00-00", isPostLogin = false, isFromOnly = false, shouldShowTime = false, time = "00:00:00" } = props;

    return (
        <div className={`flex gap-grid-sm ${!isPostLogin && "pb-grid-xl"} items-center`}>
            <SVGIcon name="calender" stroke={isPostLogin ? "stroke-neutral-low-dark" : "stroke-primary-lightest"} pathFill={isPostLogin ? "fill-neutral-low-dark" : "fill-primary-lightest"}/>
            <p className={`${isPostLogin ? "text-sm md:text-md text-neutral-low-dark" : "text-lg text-primary-lightest"}`}>
                {isFromOnly
                    ? `${getDate(prefs?.lang, dateFrom)}${shouldShowTime ? ` ( ${strings("common.lastUpdate")} ${getDate(prefs?.lang, time, "hh:mm A", "HH:mm:ss", true)} )` : ""}`
                    : `${strings("common.from")} ${getDate(prefs?.lang, dateFrom)} ${strings("common.to")} ${getDate(prefs?.lang, dateTo)} ${shouldShowTime ? `( ${strings("common.lastUpdate")} ${getDate(prefs?.lang, time, "hh:mm A", "HH:mm:ss", true)} )` : ""}`
                }
            </p>
        </div>
    );
};

export default DateRange;
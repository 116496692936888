import React from "react";
import { motion } from "framer-motion";
import SVGIcon from "./svgIcons";

const Button = (props) =>
{
    const {
        text,
        iconOnly = false,
        icon = undefined,
        iconBefore = undefined,
        iconWidth = 24,
        iconHeight = 24,
        iconStroke = "stroke-primary-lightest",
        textColor = "text-primary-lightest",
        bgColor = "bg-secondary-darkest",
        font = "text-md font-secondary",
        padding = "py-2 px-4",
        iconStyle = "fill-none",
        optionalStyle = "",
        onClick
    } = props;
    return (
        <motion.button initial={{ scale: 1 }} whileHover={{ scale: 1.05 }} className={`h-[40px] transition-colors duration-too-fast ease-in ${font} ${bgColor} ${textColor} ${padding} ${optionalStyle} rounded-full`} onClick={onClick}>
            {iconBefore && <SVGIcon name={iconBefore} width={iconWidth} height={iconHeight} className={iconStyle} stroke={iconStroke}/>}
            {!iconOnly && <p>{text}</p>}
            {icon && <SVGIcon name={icon} width={iconWidth} height={iconHeight} className={iconStyle} stroke={iconStroke}/>}
        </motion.button>
    );
};

export default Button;
import React, { useState } from "react";

import LineMsg from "./lineMsg";
import DropdownMenu from "./dropdownMenu";
import RemovableItemWithDropdown from "./removableItemWithDropdown";

import { strings } from "../../util/formatter";

export const CONTRIBUTOR = [
    {
        label: "مايا آنجلو",
        value: "maya_angelou"
    },
    {
        label: "اياد عبدالرحمن",
        value: "eyad_abdulrahman"
    }
];

export const CONTRIBUTOR_TYPE = [
    {
        label: "مؤلف",
        value: "author"
    },
    {
        label: "مترجم",
        value: "translator"
    }
];

const AddProductStep4 = () =>
{
    const [contributor, setContributor] = useState({});
    const [selectedContributorList, setSelectedContributorList] = useState([]);
    const [shouldShowErr, setShouldShowErr] = useState(false);

    const addContributor = (item) =>
    {
        setContributor({});
        if (!(selectedContributorList?.some(contributor => contributor.value === item.value)))
        {
            setShouldShowErr(false);
            setSelectedContributorList([...selectedContributorList, item]);
        }
        else
        {
            setShouldShowErr(true);
        }
    };

    const removeContributor = (item) =>
    {
        const temp = selectedContributorList;
        const arr = temp?.filter(contributor => contributor.value !== item.value);
        setSelectedContributorList([...arr]);
    };

    const addContributorType = (type, item) =>
    {
        const temp = selectedContributorList;
        const foundItem = temp?.find(contributor => contributor.value === item.value);

        if (foundItem)
        {
            foundItem.type = type;
            setSelectedContributorList([...temp]);
        }
    };

    return (
        <>
            <div className={`w-full pb-grid-sm`}>
                <DropdownMenu
                    list={CONTRIBUTOR}
                    labelKey="label"
                    placeholder={strings("placeholder.selectContributor")}
                    selectedValue={contributor}
                    setSelected={addContributor}
                />
                {shouldShowErr && <LineMsg type="error" msg={strings("message.contributorAlreadyExists")}/>}
            </div>
            {selectedContributorList?.length > 0 && <div className="h-[1px] mb-grid-sm bg-primary-light"/>}
            <div>
                {selectedContributorList?.length > 0 &&
                    selectedContributorList?.map((item, index) =>
                        (
                            <RemovableItemWithDropdown
                                key={index}
                                index={index}
                                item={item}
                                placeholder={strings("placeholder.contributorType")}
                                labelKey={"label"}
                                selected={item?.type?.label ? item?.type : {}}
                                dropdownList={CONTRIBUTOR_TYPE}
                                listLength={selectedContributorList?.length}
                                addValue={(e) => addContributorType(e, item)}
                                removeItem={() => removeContributor(item)}
                            />
                        ))
                }
            </div>
        </>
    );
};

export default AddProductStep4;
import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from "recharts";

const BarChartView = (props) =>
{
    const { data, labelKey, valueKey, showLegends = false } = props;
    return (
        <ResponsiveContainer width="100%" height={250}>
            <BarChart
                data={data}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            >
                <CartesianGrid strokeDasharray="3 3" width="100%" />
                <XAxis dataKey={labelKey}/>
                <YAxis dataKey={valueKey} tick={<CustomizedYAxisTick/>} />
                {/* <Tooltip content={<CustomTooltip />} /> */}
                <Bar dataKey={valueKey} fill="#273245" barSize={15} />
                {(showLegends === true)
                    ? <Legend
                        layout="vertical"
                    />
                    : null
                }
            </BarChart>
        </ResponsiveContainer>
    );
};

const CustomizedYAxisTick = ({ x, y, payload }) =>
{
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0}
                textAnchor="start"
                fill="#666">{new Intl.NumberFormat("en").format(payload.value)}</text>
        </g>
    );
};

// const CustomTooltip = ({ active, payload, label }) =>
// {
//     if (active && payload && payload.length)
//     {
//         return (
//             <div className="color-background-bg p-1" style={{ border: "1px solid #000" }}>
//                 <p className="label">{`${label} (${payload[0]?.payload?.[value]})`}</p>
//             </div>
//         );
//     }
// };

export default BarChartView;
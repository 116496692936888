import React, { useContext } from "react";

import { Link, useLocation } from "react-router-dom";

import CachedImg from "./cachedImg";

import { SessionContext } from "../../util/context";
import constant from "../../config/constant";
import { getLangCountryURL, strings } from "../../util/formatter";

import LOGO from "../../assets/image/logo_horizontal.png";
import LOGO_EN from "../../assets/image/logo_horizontal_en.png";
import FACEBOOK from "../../assets/image/social_icons_facebook.png";
import X from "../../assets/image/social_icons_x.png";
import INSTAGRAM from "../../assets/image/social_icons_instagram.png";
import LINKEDIN from "../../assets/image/social_icons_linkedin.png";
import SNAPCHAT from "../../assets/image/social_icons_snapchat.png";
import TIKTOK from "../../assets/image/social_icons_tiktok.png";
import YOUTUBE from "../../assets/image/social_icons_youtube.png";

const Footer = () =>
{
    const { prefs, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const location = useLocation();
    const FOOTER_SECTION = [
        {
            title: "title.myAccount",
            sortId: 0,
            items: [
                {
                    key: "login",
                    isModal: true,
                    path: "login",
                    title: "title.login",
                    sortId: 0
                },
                {
                    key: "signup",
                    isModal: true,
                    path: "signup",
                    title: "title.createAccount",
                    sortId: 1
                },
                {
                    key: "resetPassword",
                    isModal: true,
                    path: "resetPassword",
                    title: "title.resetPassword",
                    sortId: 2
                }
            ]
        },
        {
            title: "title.policies",
            sortId: 1,
            items: [
                {
                    key: "privacyPolicy",
                    isModal: false,
                    path: "page/privacy-policy",
                    title: "title.privacyPolicy",
                    sortId: 0
                },
                {
                    key: "usageAgreement",
                    isModal: false,
                    path: "page/usage-agreement",
                    title: "title.usageAgreement",
                    sortId: 1
                },
                {
                    key: "publishersAgreement",
                    isModal: false,
                    path: "page/publisher-agreement",
                    title: "title.publishersAgreement",
                    sortId: 2
                },
                {
                    key: "distributorsAgreement",
                    isModal: false,
                    path: "page/distributor-agreement",
                    title: "title.distributorsAgreement",
                    sortId: 3
                }
            ]
        },
        {
            title: "title.information",
            sortId: 2,
            items: [
                {
                    key: "competitionCriteria",
                    isModal: false,
                    path: "page/classification-competition-criteria",
                    title: "title.competitionCriteria",
                    sortId: 0
                },
                {
                    key: "auditingAndComplianceAudit",
                    isModal: false,
                    path: "page/compliance-audit-standards",
                    title: "title.auditingAndComplianceAudit",
                    sortId: 1
                },
                {
                    key: "informationSecurity",
                    isModal: false,
                    path: "page/information-security",
                    title: "title.informationSecurity",
                    sortId: 2
                },
                {
                    key: "developersGuide",
                    isModal: false,
                    path: "page/developers-guide",
                    title: "title.developersGuide",
                    sortId: 3
                },
                {
                    key: "FAQ",
                    isModal: false,
                    path: "page/FAQ",
                    title: "title.FAQ",
                    sortId: 4
                }
            ]
        },
        {
            title: "title.about",
            sortId: 3,
            items: [
                {
                    key: "aboutMofahres",
                    isModal: false,
                    path: "page/about",
                    title: "title.aboutMofahres",
                    sortId: 0
                },
                {
                    key: "aboutMelad",
                    isModal: false,
                    path: "page/dar-melad",
                    title: "title.aboutMelad",
                    sortId: 1
                },
                {
                    key: "beOurPartner",
                    isModal: false,
                    path: "page/be-our-partner",
                    title: "title.beOurPartner",
                    sortId: 2
                }
            ]
        }
    ];

    const SOCIAL_APP = [
        {
            key: "linkedIn",
            path: "https://linkedin.com/mofahres",
            img: LINKEDIN,
            sortId: 0
        },
        {
            key: "x",
            path: "https://x.com/mofahres",
            img: X,
            sortId: 1
        },
        {
            key: "instagram",
            path: "https://instagram.com/mofahres",
            img: INSTAGRAM,
            sortId: 2
        },
        {
            key: "snapchat",
            path: "https://snapchat.com/mofahres",
            img: SNAPCHAT,
            sortId: 3
        },
        {
            key: "tiktok",
            path: "https://tiktok.com/mofahres",
            img: TIKTOK,
            sortId: 4
        },
        {
            key: "youtube",
            path: "https://youtube.com/mofahres",
            img: YOUTUBE,
            sortId: 5
        },
        {
            key: "facebook",
            path: "https://facebook.com/mofahres",
            img: FACEBOOK,
            sortId: 6
        }
    ];
    return (
        <div className={`w-full transition-colors duration-middle ease-in text-primary-lightest`}>
            {!constant.hide_footer_details_path?.some(path => location.pathname.includes(path)) &&
                <div className="w-full lg:container px-grid-lg lg:px-0 lg:mx-auto flex justify-start lg:gap-grid-xl xl:gap-grid-xxl py-grid-xxl">
                    <div className="hidden lg:block w-full lg:w-1/5 self-start">
                        <CachedImg src={prefs?.lang === constant.lang.values[0] ? LOGO : LOGO_EN} alt={strings("mofahres")} className={`w-[${prefs?.lang === constant.lang.values[0] ? "170" : "200"}px]`}/>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row gap-grid-lg lg:gap-0 justify-between font-primary text-xl">
                        {FOOTER_SECTION?.map((section, index) => (
                            <div key={index} className={`w-full lg:w-1/5 ${prefs?.lang === constant.lang.values[0] ? "pt-[3px]" : "pt-[6px]"}`}>
                                {strings(section.title)}
                                <hr className="w-[60px] h-grid-xxs mb-grid-md mt-grid-xs bg-primary-darkest border-none rounded-full"/>
                                {section?.items?.map((subSection, subIndex) => (
                                    <div key={subIndex} className="font-secondary text-md pb-grid-sm">
                                        {subSection?.isModal
                                            ? <div
                                                className="cursor-pointer"
                                                onClick={() =>
                                                {
                                                    setActiveModal(subSection?.key);
                                                    setModalOptions({ ...modalOptions, title: strings(`title.${subSection?.key}`) });
                                                }}
                                            >
                                                {strings(subSection.title)}
                                            </div>
                                            : <Link
                                                to={`${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${subSection.path}`}
                                            >
                                                {strings(subSection.title)}
                                            </Link>
                                        }
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            }
            <div className="py-grid-sm bg-primary-dark">
                <div className="w-full lg:container px-grid-lg lg:px-0 lg:mx-auto flex flex-col md:flex-row gap-grid-lg md:gap-0 justify-between items-center text-sm">
                    {strings("common.madeInRiyadh")}
                    <div className="flex gap-grid-sm">
                        {SOCIAL_APP?.map((app, index) => (
                            <Link key={index} to={app.path} className="w-[30px] h-[30px] rounded-full bg-primary-darkest flex justify-center items-center">
                                <CachedImg src={app.img} alt={strings("mofahres")} className="w-[13px]"/>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
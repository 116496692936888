import React from "react";
import { motion } from "framer-motion";
import ShowAll from "./showAll";
import { strings } from "../../util/formatter";

const Tabs = (props) =>
{
    const {
        active,
        setActive,
        isModal = false,
        list,
        shouldShowAll = false,
        showAllRout = "",
        children
    } = props;
    return (
        !isModal
            ? <>
                <motion.div className={`block md:flex ${shouldShowAll ? "flex-row-reverse justify-between" : "justify-start"}`}>
                    {shouldShowAll && <ShowAll route={showAllRout}/>}
                    <div className="flex">
                        {list.map((item, index) =>
                            (
                                <div key={index} className={`relative py-grid-sm px-grid-sm sm:px-grid-md md:px-grid-xl text-center cursor-pointer transition-color ease-linear duration-fast ${active !== item.key ? "hover:bg-primary-darkest transition-all ease-linear duration-200 text-primary-lightest" : "text-secondary-darkest"} bg-primary-dark ${index === 0 ? "rounded-ss-md" : index === list?.length - 1 ? "rounded-se-md" : ""}`} onClick={() => setActive(item.key)}>
                                    {active === item.key && <div className={`absolute inset-0 bg-primary-lightest ${index === 0 ? "rounded-ss-md" : index === list?.length - 1 ? "rounded-se-md" : ""}`}/>}
                                    <span className="relative z-20">{strings(item.label)}</span>
                                </div>
                            )
                        )}
                    </div>
                </motion.div>
                <div className="w-full bg-primary-lightest shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)] rounded-e-md rounded-es-md">
                    { children }
                </div>
            </>

            : <>
                <motion.div className={`w-full block md:flex justify-start`}>
                    <div className="w-full flex">
                        {list.map((item, index) =>
                            (
                                <div key={index} className={`w-full relative py-grid-sm px-grid-xs text-center text-sm cursor-pointer transition-color ease-linear duration-fast ${active !== item.key ? "hover:bg-primary-regular transition-opacity ease-linear duration-200 text-primary-lightest" : "text-secondary-darkest"} bg-primary-dark`} onClick={() => setActive(item.key)}>
                                    {active === item.key && <div className={`absolute inset-0 bg-primary-lightest`}/>}
                                    <span className="relative z-20">{strings(item.label)}</span>
                                </div>
                            )
                        )}
                    </div>
                </motion.div>
                <div className="w-full">
                    { children }
                </div>
            </>
    );
};

export default Tabs;
import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import CachedImg from "./cachedImg";
import Rank from "./rank";

import { SessionContext } from "../../util/context";
import { getLangCountryURL } from "../../util/formatter";
import Avatar from "./avatar";

const ChartContestantSmall = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const {
        item,
        containerClassName = "w-[190px] h-[270px] bg-primary-light text-secondary-darkest p-grid-md cursor-pointer rounded-md",
        imgClassName = "w-[70px] h-[115px] mx-auto",
        textClassName = "text-base text-center pt-grid-sm",
        secondTextClassName = "text-xs text-center pt-grid-sm",
        shouldScale = true,
        roundedImg = false,
        size = "sm",
        isLastItem,
        lastItemRef
    } = props;
    return (
        <Link to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`}>
            <motion.div ref={isLastItem ? lastItemRef : null} whileHover={{ scale: shouldScale ? 1.05 : 1 }} className={containerClassName}>
                <div>
                    {roundedImg
                        ? <Avatar src={item?.cover || item?.image} size={size}/>
                        : <CachedImg src={item?.cover || item?.image} className={imgClassName}/>
                    }
                    {item?.rank && <Rank rank={item?.rank} shouldShowRankText={true} rankStatus={item?.rank_status} />}
                </div>

                <p className={textClassName}>
                    {item?.name}
                </p>
                {item?.contributor_name &&
                    <p className={secondTextClassName}>
                        {item?.contributor_name}
                    </p>
                }
            </motion.div>
        </Link>
    );
};

export default ChartContestantSmall;
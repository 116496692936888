import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";
import constant from "../../config/constant";
import { getLangCountryURL, strings } from "../../util/formatter";

import LOGO from "../../assets/image/logo_horizontal_dark_ar.png";
import LOGO_EN from "../../assets/image/logo_horizontal_dark_en.png";
import CachedImg from "./cachedImg";

const SubMenu = () =>
{
    const { prefs } = useContext(SessionContext);
    const location = useLocation();
    const [active, setActive] = useState(location.pathname);

    useEffect(() =>
    {
        setActive(location.pathname);
    }, [location.pathname]);

    return (
        <div
            data-lenis-prevent
            className={`relative hidden lg:block h-[calc(100vh-32px)] max-w-[60%] min-w-[60%] sm:max-w-[30%] sm:min-w-[30%] md:max-w-[25%] md:min-w-[25%] lg:max-w-[270px] lg:min-w-[270px] xl:max-w-[300px] xl:min-w-[300px] transition-colors duration-middle ease-in text-md bg-neutral-high-regular text-neutral-low-regular overflow-y-scroll overflow-x-hidden rounded-s-md`}
        >
            <div className="h-[130px] max-w-[60%] min-w-[60%] sm:max-w-[30%] sm:min-w-[30%] md:max-w-[25%] md:min-w-[25%] lg:max-w-[270px] lg:min-w-[270px] xl:max-w-[300px] xl:min-w-[300px] absolute z-0 top-32 -end-2/3 rounded-full bg-gradient-to-r from-primary-regular to-secondary-regular blur-[70px]"/>
            <CachedImg src={prefs?.lang === constant.lang.values[0] ? LOGO : LOGO_EN } alt="mofahres" className="relative max-w-[120px] pt-grid-sm pb-grid-lg mx-grid-sm z-10"/>
            {constant.menu_section.map((section) =>
            {
                return (
                    <div key={section.sortId} className="relative pb-grid-xl text-sm z-10">
                        <div className="mx-grid-sm px-grid-sm pb-grid-xs font-primary text-neutral-low-dark">
                            {strings(section.title)}
                        </div>
                        <div>
                            {section.items.map(subSection =>
                            {
                                return (
                                    <Link
                                        key={subSection.key}
                                        to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard/${subSection.path}`}
                                        onClick={() => setActive(subSection.path)}
                                    >
                                        <div className={`mx-grid-sm py-grid-xs px-grid-sm flex gap-grid-sm items-center cursor-pointer text-neutral-low-regular ${(active?.includes(subSection?.path) || (active === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard` && subSection?.path === "overview")) && "text-primary-lightest bg-primary-regular rounded-xs transition-colors ease-linear duration-fast"} ${active !== subSection.path && "hover:opacity-60 transition-opacity ease-linear duration-fast"}`}>
                                            <SVGIcon name={subSection.icon} width={16} height={16} viewBox={subSection.icon === "shop" ? "0 0 24 24" : "0 0 16 16"} className={subSection.icon === "notebook" ? (active?.includes(subSection?.path) ? "fill-primary-lightest" : "fill-neutral-low-regular") : "fill-none"} stroke={(active?.includes(subSection?.path) || (active === `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/dashboard` && subSection?.path === "overview")) ? "stroke-primary-lightest" : "stroke-neutral-low-regular"}/>
                                            {strings(subSection.title)}
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SubMenu;
import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import SearchInput from "./searchInput";
import Button from "./button";
import UserDropdown from "./userDropdown";
import Loader from "./loader";

import { SessionContext } from "../../util/context";
import { getLangCountryURL, strings } from "../../util/formatter";

import eyadImage from "../../assets/image/Eyad.jpg";

export const USER_INFO = {
    id: "123332",
    display_name: "اياد عبدالرحمن",
    img: eyadImage,
    producer_name: "شركة دار ميلاد للنشر والتوزيع"
};

export const USER_MENU = [
    {
        value: "myAccount",
        label: strings("common.myAccount")
    },
    {
        value: "manageEntity",
        label: strings("common.manageEntity")
    },
    {
        value: "backToMyEntities",
        label: strings("common.backToMyEntities")
    },
    {
        value: "logout",
        label: strings("common.logout")
    }
];

const PostLoginHeader = () =>
{
    const { prefs, setActiveModal, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);
    const [selected] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const isTop = scrollPosition <= 70;

    const handleScroll = () =>
    {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useEffect(() =>
    {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () =>
        {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() =>
    {
        if (Object.keys(selected)?.length > 0)
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setIsLoading(false);
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${selected?.path}`);
            }, 3000);
        }
    }, [selected]);

    const handleUserDropdownClick = (item) =>
    {
        if (item.value === "myAccount")
        {
            setActiveModal("userAccount");
            setModalOptions({
                title: strings("common.myAccount"),
                containerPadding: "p-0"
            });
        }

        if (item.value === "manageEntity")
        {
            setActiveModal("manageEntity");
            setModalOptions({
                title: strings("common.manageEntity"),
                containerPadding: "p-0"
            });
        }

        if (item.value === "backToMyEntities")
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setIsLoading(false);
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/entity`);
            }, 2000);
        }

        if (item.value === "logout")
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setIsLoading(false);
                navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}`);
            }, 2000);
        }
    };

    const handleNotificationsClick = () =>
    {

    };

    return (
        <div className={`w-full absolute pt-grid-md px-grid-md flex justify-between items-center z-50 transition-all duration-fast ease-in text-primary-dark ${isTop ? "" : "bg-neutral-high-lightest shadow-[0_1px_3px_1px_rgba(var(--color-secondary-darkest),20%)]"}`}>
            {isLoading && <Loader />}
            <div className="w-[45%]">
                <SearchInput theme={"post-login"} shouldShowShadow={false}/>
            </div>
            <div className="w-[40%] flex gap-grid-sm justify-between items-center">
                <Button
                    iconOnly={true}
                    icon="bell"
                    iconWidth={25}
                    iconHeight={24}
                    bgColor="bg-neutral-high-regular"
                    padding="p-[10px]"
                    iconStroke="stroke-neutral-low-regular"
                    optionalStyle="flex justify-center items-center rounded-full"
                    onClick={handleNotificationsClick}
                />
                <Button
                    iconOnly={true}
                    icon="moon"
                    iconWidth={25}
                    iconHeight={24}
                    bgColor="bg-neutral-high-regular"
                    padding="p-[10px]"
                    iconStyle="fill-neutral-low-regular"
                    optionalStyle="flex justify-center items-center rounded-full"
                    onClick={handleNotificationsClick}
                />
                <div className="h-[2.5rem] w-[1px] bg-neutral-high-dark"/>
                <div className="w-full">
                    <UserDropdown
                        list={USER_MENU}
                        outLabelKey={USER_INFO?.display_name}
                        secondOutLabelKey={USER_INFO?.producer_name}
                        imgKey={USER_INFO?.img}
                        inLabelKey="label"
                        valueKey="value"
                        xAxis="end-0"
                        setSelected={(e) => handleUserDropdownClick(e)}
                    />
                </div>
            </div>
        </div>
    );
};

export default PostLoginHeader;
import React, { createRef, useContext, useState } from "react";

import { strings } from "../util/formatter";
import Label from "../view/component/label";
import Input from "../view/component/input";
import LineMsg from "../view/component/lineMsg";
import Button from "../view/component/button";
import DatePickerButton from "../view/component/datePicketButton";
import FileInput from "../view/component/fileInput";
import DropdownMenu from "../view/component/dropdownMenu";

import { SessionContext } from "../util/context";

const CreateEntity = () =>
{
    const { setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const documentFileRef = createRef(null);
    const entityLogoRef = createRef(null);
    const [entityNameAR, setEntityNameAR] = useState("");
    const [entityNameEN, setEntityNameEN] = useState("");
    const [selectedEntityType, setSelectedEntityType] = useState({});
    const [selectedDocumentType, setSelectedDocumentType] = useState({});
    const [selectedDocumentCountry, setSelectedDocumentCountry] = useState({});
    const [documentNumber, setDocumentNumber] = useState("");
    const [documentExpiryDate, setDocumentExpiryDate] = useState(null);
    const [documentFile, setDocumentFile] = useState({});
    const [entityLogo, setEntityLogo] = useState({});

    const ENTITY_TYPE = [
        {
            id: 1,
            name: "منشأة حكومية"
        },
        {
            id: 2,
            name: "منشأة خاصة"
        }
    ];

    const DOCUMENT_TYPE = [
        {
            id: 1,
            name: "سجل تجاري"
        },
        {
            id: 2,
            name: "هوية وطنية"
        }
    ];

    const DOCUMENT_ISSUED_COUNTRY = [
        {
            id: 1,
            name: "المملكة العربية السعودية"
        }
    ];

    const selectDocumentFile = () =>
    {
        if (documentFileRef.current !== null)
        {
            documentFileRef.current.click();
        }
    };

    const selectEntityLogo = () =>
    {
        if (entityLogoRef.current !== null)
        {
            entityLogoRef.current.click();
        }
    };

    const onSubmit = () =>
    {
        setModalOptions({
            ...modalOptions,
            title: strings("title.subscriptions")
        });
        setActiveModal("subscription");
    };

    return (
        <>
            <div className="flex justify-between gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.entityNameAR")}/>
                    <Input
                        name="entityNameAR"
                        value={entityNameAR}
                        setValue={setEntityNameAR}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.entityNameEN")}/>
                    <Input
                        name="entityNameEN"
                        value={entityNameEN}
                        setValue={setEntityNameEN}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.entityType")}/>
                <DropdownMenu
                    list={ENTITY_TYPE}
                    labelKey="name"
                    placeholder={strings("placeholder.selectEntity")}
                    selectedValue={selectedEntityType}
                    setSelected={setSelectedEntityType}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="flex justify-between gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentType")}/>
                    <DropdownMenu
                        list={DOCUMENT_TYPE}
                        labelKey="name"
                        placeholder={strings("placeholder.selectType")}
                        selectedValue={selectedDocumentType}
                        setSelected={setSelectedDocumentType}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentNumber")}/>
                    <Input
                        name="documentNumber"
                        value={documentNumber}
                        setValue={setDocumentNumber}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
            </div>
            <div className="flex justify-between gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentIssuedCountry")}/>
                    <DropdownMenu
                        list={DOCUMENT_ISSUED_COUNTRY}
                        labelKey="name"
                        placeholder={strings("placeholder.selectCountry")}
                        selectedValue={selectedDocumentCountry}
                        setSelected={setSelectedDocumentCountry}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentExpiryDate")}/>
                    <DatePickerButton
                        value={documentExpiryDate}
                        setValue={setDocumentExpiryDate}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.documentFile")}/>
                <FileInput
                    fileRef={documentFileRef}
                    value={documentFile}
                    setValue={setDocumentFile}
                    selectFile={selectDocumentFile}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.entityLogo")}/>
                <FileInput
                    fileRef={entityLogoRef}
                    value={entityLogo}
                    setValue={setEntityLogo}
                    selectFile={selectEntityLogo}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="text-end pt-grid-lg">
                <Button
                    text={strings("button.next")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={onSubmit}
                />
            </div>
        </>
    );
};

export default CreateEntity;
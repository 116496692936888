import React, { createRef, useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";
import FileInput from "./fileInput";
import DatePickerButton from "./datePicketButton";

import { strings } from "../../util/formatter";

export const TYPE = [
    {
        id: 1,
        name: "منشأة حكومية"
    },
    {
        id: 2,
        name: "منشأة خاصة"
    }
];

export const DOCUMENT_TYPE = [
    {
        id: 1,
        name: "سجل تجاري"
    },
    {
        id: 2,
        name: "هوية وطنية"
    }
];

export const DOCUMENT_ISSUED_COUNTRY = [
    {
        id: 1,
        name: "المملكة العربية السعودية"
    }
];

const ManageEntityDetails = () =>
{
    const logoImgRef = createRef(null);
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [type, setType] = useState({});
    const [documentType, setDocumentType] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");
    const [documentExpiryDate, setDocumentExpiryDate] = useState(Date.now());
    const [documentIssuedCountry, setDocumentIssuedCountry] = useState("");
    const [logoImg, setLogoImg] = useState({});

    const selectLogoImg = () =>
    {
        if (logoImgRef.current !== null)
        {
            logoImgRef.current.click();
        }
    };

    return (
        <>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.entityName")}/>
                <Input
                    name="name"
                    value={name}
                    setValue={setName}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="w-1/2 pb-grid-sm">
                <Label label={strings("label.entityCode")}/>
                <Input
                    name="code"
                    value={code}
                    setValue={setCode}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.entityType")}/>
                <DropdownMenu
                    list={TYPE}
                    labelKey="name"
                    placeholder={strings("placeholder.selectType")}
                    selectedValue={type}
                    setSelected={setType}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="flex justify-between gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentType")}/>
                    <DropdownMenu
                        list={DOCUMENT_TYPE}
                        labelKey="name"
                        placeholder={strings("placeholder.selectType")}
                        selectedValue={documentType}
                        setSelected={setDocumentType}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.documentNumber")}/>
                    <Input
                        name="documentNumber"
                        value={documentNumber}
                        setValue={setDocumentNumber}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.documentExpiryDate")}/>
                <DatePickerButton
                    value={documentExpiryDate}
                    setValue={setDocumentExpiryDate}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.documentIssuedCountry")}/>
                <DropdownMenu
                    list={DOCUMENT_ISSUED_COUNTRY}
                    labelKey="name"
                    placeholder={strings("placeholder.selectCountry")}
                    selectedValue={documentIssuedCountry}
                    setSelected={setDocumentIssuedCountry}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="pb-grid-sm">
                <Label label={strings("label.entityLogo")}/>
                <FileInput
                    fileRef={logoImgRef}
                    value={logoImg}
                    setValue={setLogoImg}
                    selectFile={selectLogoImg}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
        </>
    );
};

export default ManageEntityDetails;
import React, { useContext, useState } from "react";

import { strings } from "../util/formatter";
import Label from "../view/component/label";
import Input from "../view/component/input";
import LineMsg from "../view/component/lineMsg";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";

const Signup = () =>
{
    const { setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onOTPVerify = () =>
    {
        console.log("verified");
    };

    const onClick = () =>
    {
        setModalOptions({
            ...modalOptions,
            title: strings("title.otp"),
            onVerify: onOTPVerify,
            email
        });
        setActiveModal("otp");
    };

    return (
        <>
            <div className="flex justify-between gap-4">
                <div className="w-full pb-4">
                    <Label label={strings("label.firstName")}/>
                    <Input
                        name="firstName"
                        value={firstName}
                        placeholder={strings("placeholder.firstName")}
                        setValue={setFirstName}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
                <div className="w-full pb-4">
                    <Label label={strings("label.lastName")}/>
                    <Input
                        name="lastName"
                        value={lastName}
                        placeholder={strings("placeholder.lastName")}
                        setValue={setLastName}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
            </div>
            <div className="pb-4">
                <Label label={strings("label.email")}/>
                <Input
                    name="email"
                    value={email}
                    placeholder={strings("placeholder.email")}
                    setValue={setEmail}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="pb-4">
                <Label label={strings("label.password")}/>
                <Input
                    name="password"
                    value={password}
                    type="password"
                    placeholder={strings("placeholder.password")}
                    setValue={setPassword}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="text-end pt-grid-lg">
                <Button
                    text={strings("button.signup")}
                    bgColor="bg-secondary-darkest"
                    onClick={onClick}
                />
            </div>
        </>
    );
};

export default Signup;
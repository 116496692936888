import React, { useState } from "react";

import RemovableItemWithDropdown from "./removableItemWithDropdown";

import { strings } from "../../util/formatter";

export const USER_TYPE = [
    {
        label: "مشرف",
        value: "admin"
    },
    {
        label: "مراقب",
        value: "watcher"
    }
];

const ManageEntityUsers = (props) =>
{
    const { entity, setEntity } = props;
    const [entityUsers, setEntityUsers] = useState(entity?.users);

    const removeUser = (item) =>
    {
        const temp = entityUsers;
        const arr = temp?.filter(user => user.id !== item.id);
        setEntityUsers([...arr]);
        setEntity({ users: arr });
    };

    const addUserType = (type, item) =>
    {
        const temp = entityUsers;
        const foundItem = temp?.find(category => category.value === item.value);

        if (foundItem)
        {
            foundItem.type = type;
            setEntityUsers([...temp]);
            setEntity({ users: temp });
        }
    };

    return (
        <>
            {entityUsers?.length > 0 &&
                entityUsers?.map((user, index) =>
                    (
                        <RemovableItemWithDropdown
                            key={index}
                            index={index}
                            item={user}
                            placeholder={strings("placeholder.userType")}
                            labelKey={"email"}
                            selected={user?.type?.label ? user?.type : {}}
                            dropdownList={USER_TYPE}
                            listLength={entityUsers?.length}
                            addValue={(e) => addUserType(e, user)}
                            removeItem={() => removeUser(user)}
                        />
                    ))
            }
        </>
    );
};

export default ManageEntityUsers
;
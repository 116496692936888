import React from "react";

import DropdownMenu from "../component/dropdownMenu";
import DatePickerButton from "../component/datePicketButton";

import { strings } from "../../util/formatter";

const ListOfSales = (props) =>
{
    const { list, totalSales, filterType = "none", filterList = [], filterValue, setFilterValue } = props;

    return (
        <>
            {filterType === "none"
                ? null
                : filterType === "date"
                    ? <div className="mx-grid-md md:mx-0 p-grid-lg flex justify-end">
                        <DatePickerButton
                            value={filterValue}
                            setValue={setFilterValue}
                        />
                    </div>
                    : <div className="mx-grid-md md:mx-0 p-grid-lg flex justify-end">
                        <DropdownMenu
                            list={filterList}
                            labelKey="name"
                            selectedValue={filterValue}
                            setSelected={setFilterValue}
                        />
                    </div>
            }
            <div className="divide-y divide-solid divide-primary-light p-grid-md">
                {list.map((item, index) => (
                    <div key={index} className="p-grid-sm flex gap-grid-sm items-center justify-between text-secondary-darkest text-sm">
                        <div className="flex gap-grid-xs items-center">
                            {item?.name}
                        </div>
                        <div className="flex items-center">
                            {`${item?.sales} ${strings("common.version")}`}
                        </div>
                    </div>
                ))}
                <div className="p-grid-sm text-secondary-darkest text-sm text-end font-primary">
                    {`${totalSales} ${strings("common.version")}`}
                </div>
            </div>
        </>
    );
};

export default ListOfSales;
import React, { useState } from "react";

import DateRange from "../view/component/dateRange";
import Loader from "../view/component/loader";
import Button from "../view/component/button";
import DataCardWithIcon from "../view/component/dataCardWithIcon";
import DataCardPercentage from "../view/component/dataCardPercentage";
import PieChartView from "../view/component/pieChartView";
import BarChartView from "../view/component/barChartView";
import LineChartView from "../view/component/lineChartView";

import { generateRandomMonochromaticArray, numberToNumberWithCommas, strings } from "../util/formatter";

export const barChartDataForCities = [
    {
        name: "City A",
        uv: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "City B",
        uv: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "City C",
        uv: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "City D",
        uv: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "City E",
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "City F",
        uv: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "City G",
        uv: 3490,
        pv: 4300,
        amt: 2100
    }
];

export const lineChartDataForCategories = [
    {
        name: "Category A",
        uv: 6000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "Category B",
        uv: 5000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "Category C",
        uv: 1000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "Category D",
        uv: 4780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "Category E",
        uv: 3890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "Category F",
        uv: 1390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "Category G",
        uv: 490,
        pv: 4300,
        amt: 2100
    }
];

export const pieChartData = [
    { name: "City A", value: 400 },
    { name: "City B", value: 300 },
    { name: "City C", value: 300 },
    { name: "City D", value: 102 },
    { name: "City E", value: 500 },
    { name: "City F", value: 340 },
    { name: "City G", value: 100 },
    { name: "City H", value: 200 },
    { name: "City I", value: 420 }
];

const Dashboard = () =>
{
    const [isLoading, setIsLoading] = useState(false);
    const FILTER_BY =
    [
        {
            label: strings("dashboard.thisDay"),
            value: "day"
        },
        {
            label: strings("dashboard.thisWeek"),
            value: "week"
        },
        {
            label: strings("dashboard.thisMonth"),
            value: "month"
        },
        {
            label: strings("dashboard.thisQuarter"),
            value: "quarter"
        },
        {
            label: strings("dashboard.thisYear"),
            value: "year"
        }
    ];
    const [activeFilter, setActiveFilter] = useState(FILTER_BY[0]);

    const filterBy = (item) =>
    {
        if (activeFilter.value !== item.value)
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setActiveFilter(item);
                setIsLoading(false);
            }, 3000);
        }
    };

    return (
        <div className="p-grid-md">
            {isLoading && <Loader />}
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-grid-md pt-grid-md pb-grid-xl">
                <div className="text-neutral-low-dark">
                    <p className="pb-grid-xs text-md md:text-lg font-primary">
                        {strings("dashboard.welcome")}
                    </p>
                    <DateRange
                        dateFrom="2023-08-13"
                        isPostLogin={true}
                        isFromOnly={true}
                        shouldShowTime={true}
                        time={"13:00:00"}
                    />
                </div>
                <div className="flex gap-grid-xs">
                    {FILTER_BY.map((item, index) =>
                    {
                        return (
                            <Button
                                key={index}
                                text={item.label}
                                bgColor={`${item.value === activeFilter.value ? "bg-primary-regular" : "bg-neutral-high-regular"}`}
                                textColor={`${item.value === activeFilter.value ? "text-primary-lightest" : "text-neutral-low-dark"}`}
                                font={"text-xs md:text-sm font-secondary"}
                                padding="py-grid-xs px-grid-xs md:px-grid-sm"
                                onClick={() => filterBy(item)}
                            />
                        );
                    })}
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-grid-md pb-grid-md">
                <DataCardWithIcon
                    icon={"bookmark"}
                    iconViewBox="0 0 16 16"
                    label={strings("dashboard.copiesold")}
                    value={numberToNumberWithCommas("12876")}
                />
                <DataCardWithIcon
                    icon={"circleDollar"}
                    iconViewBox="0 0 16 16"
                    label={strings("dashboard.totalSales")}
                    value={`${numberToNumberWithCommas("35987001.45")} رس`}
                />
                <DataCardWithIcon
                    icon={"shop"}
                    iconViewBox="0 0 24 24"
                    label={strings("dashboard.participatingSellingCenters")}
                    value={numberToNumberWithCommas("3482")}
                />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-grid-md pb-grid-md">
                <DataCardPercentage
                    number={"5%+"}
                    label={strings("dashboard.publicationsGrowth")}
                    description={strings("dashboard.publicationsIssued")}
                />
                <DataCardPercentage
                    number={"9%-"}
                    label={strings("dashboard.authorsGrowth")}
                    description={strings("dashboard.authorsPublished")}
                />
                <DataCardPercentage
                    number={"0%+"}
                    label={strings("dashboard.publishersGrowth")}
                    description={strings("dashboard.publishersPublished")}
                />
                <DataCardPercentage
                    number={"400%+"}
                    label={strings("dashboard.sellingCentersGrowth")}
                    description={strings("dashboard.sellingCentersSold")}
                />
            </div>
            <div className="grid grid-rows-6 grid-flow-row md:grid-flow-col gap-grid-md pb-grid-md">
                <div className="p-grid-sm row-span-3 col-span-2 bg-neutral-high-regular text-center rounded-sm">
                    <p className="text-md text-neutral-low-dark font-primary pt-grid-sm pb-grid-lg">
                        {strings("dashboard.copiesSoldByRegions")}
                    </p>
                    <BarChartView
                        data={barChartDataForCities}
                        labelKey="name"
                        valueKey="uv"
                    />
                </div>
                <div className="p-grid-sm row-span-3 col-span-2 bg-neutral-high-regular text-center rounded-sm">
                    <p className="text-md text-neutral-low-dark font-primary pt-grid-sm pb-grid-lg">
                        {strings("dashboard.totalSalesByRegion")}
                    </p>
                    <BarChartView
                        data={barChartDataForCities}
                        labelKey="name"
                        valueKey="uv"
                    />
                </div>
                <div className="p-grid-sm md:auto-cols-auto col-span-2 md:col-span-1 row-span-6 md:row-span-full bg-neutral-high-regular text-center rounded-sm">
                    <p className="text-md text-neutral-low-dark font-primary pt-grid-sm pb-grid-lg">
                        {strings("dashboard.salesPerformanceByClassification")}
                    </p>
                    <PieChartView
                        data={pieChartData}
                        labelKey="name"
                        valueKey="value"
                        colors={generateRandomMonochromaticArray("#788EB3", "#273245", pieChartData?.length)}
                    />
                </div>
            </div>
            <div className="p-grid-sm bg-neutral-high-regular text-center rounded-sm mb-grid-md">
                <p className="text-md text-neutral-low-dark font-primary pt-grid-sm">
                    {strings("dashboard.regionsOfMostGrowing")}
                </p>
                <p className="text-xs text-neutral-low-dark font-secondary pt-grid-sm pb-grid-lg">
                    {strings("dashboard.comparedToLastPeriod")}
                </p>
                <LineChartView
                    data={barChartDataForCities}
                    labelKey="name"
                    valueKey="uv"
                />
            </div>
            <div className="p-grid-sm bg-neutral-high-regular text-center rounded-sm">
                <p className="text-md text-neutral-low-dark font-primary pt-grid-sm">
                    {strings("dashboard.mostGrowingCategories")}
                </p>
                <p className="text-xs text-neutral-low-dark font-secondary pt-grid-sm pb-grid-lg">
                    {strings("dashboard.comparedToLastPeriod")}
                </p>
                <LineChartView
                    data={lineChartDataForCategories}
                    labelKey="name"
                    valueKey="uv"
                />
            </div>
        </div>
    );
};

export default Dashboard;
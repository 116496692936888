import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import Label from "../view/component/label";
import Input from "../view/component/input";
import LineMsg from "../view/component/lineMsg";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { getLangCountryURL, strings } from "../util/formatter";

const Login = () =>
{
    const { prefs, setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onResetPasswordClick = () =>
    {
        setActiveModal("resetPassword");
        setModalOptions({
            ...modalOptions,
            title: strings("title.resetPassword")
        });
    };

    const login = () =>
    {
        setActiveModal(undefined);
        setModalOptions({});
        navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/entity`);
    };

    return (
        <>
            <div className="pb-4">
                <Label label={strings("label.email")}/>
                <Input
                    name="email"
                    value={email}
                    placeholder={strings("placeholder.email")}
                    setValue={setEmail}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="pb-4">
                <Label label={strings("label.password")}/>
                <Input
                    name="password"
                    value={password}
                    type="password"
                    placeholder={strings("placeholder.password")}
                    setValue={setPassword}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="text-end">
                <Button
                    text={strings("button.resetPassword")}
                    bgColor="transparent"
                    textColor="text-primary-500"
                    font="text-sm"
                    onClick={() => onResetPasswordClick()}
                />
            </div>
            <div className="text-end pt-grid-lg">
                <Button
                    text={strings("button.login")}
                    bgColor="bg-secondary-darkest"
                    onClick={login}
                />
            </div>
        </>
    );
};

export default Login;
import React, { useContext } from "react";

import { SessionContext } from "../../util/context";

const Textarea = (props) =>
{
    const { isPostLogin } = useContext(SessionContext);
    const { name, value, placeholder, setValue } = props;

    return (
        <textarea
            onChange={(e) => setValue(e.target.value)}
            name={name}
            value={value}
            rows={5}
            placeholder={placeholder}
            className={`w-full py-grid-sm px-grid-sm resize-none ${isPostLogin ? "bg-neutral-high-regular" : "bg-secondary-light"} text-secondary-darkest text-sm rounded-xs placeholder:text-secondary-darkest/50 focus:outline-none`}
        />
    );
};

export default Textarea;
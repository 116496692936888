import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Lenis from "@studio-freight/lenis";

import constant from "../config/constant";
import LocalStorage from "./localStorage";
import { handleSEOURL } from "./formatter";

export const SessionContext = createContext({});

const GlobalContext = ({ children }) =>
{
    const { init, set, unset, destroy } = LocalStorage;
    const [prefs, setPrefs] = useState({ ...localStorage });
    const [activeModal, setActiveModal] = useState(undefined);
    const [modalOptions, setModalOptions] = useState({});
    const [showSearchInputInHeader, setShowSearchInputInHeader] = useState(true);
    const [lenis] = useState(new Lenis());
    const location = useLocation();
    const isPostLogin = location.pathname?.includes("dashboard");
    const navigate = useNavigate();

    const setter = async (key, value) =>
    {
        await set(key, value);
        setPrefs({ ...localStorage });
    };

    const remover = async (key = "all") =>
    {
        if (key === "all")
        {
            await destroy();
        }
        else
        {
            await unset(key);
        }
        setPrefs();
    };

    const setLenis = () =>
    {
        function raf (time)
        {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);
    };

    useEffect(() =>
    {
        if (!prefs.theme && !prefs.lang && !prefs.country_code) init();
        setLenis();
    }, []);

    useEffect(() =>
    {
        const pathnameList = location?.pathname?.split("/");
        const localizationParams = pathnameList[1]?.split("-");
        const setLangAndCountry = async () =>
        {
            if (localizationParams?.[0] && localizationParams?.[1])
            {
                await setter("lang", localizationParams[0]);
                await setter("country_code", localizationParams[1]);
            }
            else
            {
                await setter("lang", constant.lang.values?.[0]);
                await setter("country_code", constant.country.values?.[0]);
            }
        };
        if (constant.lang.values?.includes(localizationParams[0]) && constant.country.values?.includes(localizationParams[1]))
        {
            setLangAndCountry();
            navigate(handleSEOURL(`${location?.pathname}${location?.search ? location?.search : ""}`, localizationParams[0], localizationParams[1]));
        }
        else if (location?.pathname === "/")
        {
            setLangAndCountry();
            navigate(handleSEOURL(`${location?.pathname}${location?.search ? location?.search : ""}`, constant.lang.values?.[0], constant.country.values?.[0]));
        }
    }, [prefs.country_code, prefs.lang]);

    return (
        <SessionContext.Provider value={{ setter, remover, prefs, lenis, activeModal, setActiveModal, modalOptions, setModalOptions, showSearchInputInHeader, setShowSearchInputInHeader, isPostLogin }}>
            {children}
        </SessionContext.Provider>
    );
};

export default GlobalContext;
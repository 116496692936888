import React, { useContext, useState } from "react";

import Label from "../view/component/label";
import DropdownMenu from "../view/component/dropdownMenu";
import LineMsg from "../view/component/lineMsg";
import Input from "../view/component/input";
import Textarea from "../view/component/textarea";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const TYPE = [
    {
        name: "متجر تجزئة",
        value: "branch"
    },
    {
        name: "متجر إلكتروني",
        value: "online_store"
    }
];

export const COUNTY = [
    {
        name: "المملكة العربية السعودية",
        value: "sa"
    },
    {
        name: "الإمارات العربية المتحدة",
        value: "ea"
    }
];

export const CITY = [
    {
        name: "الرياض",
        value: "riyadh"
    },
    {
        name: "جدة",
        value: "jeddah"
    }
];

const AddOutlet = () =>
{
    const { setActiveModal } = useContext(SessionContext);
    const [type, setType] = useState({});
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [country, setCountry] = useState({});
    const [city, setCity] = useState({});
    const [url, setURL] = useState("");

    const add = () =>
    {
        setActiveModal(undefined);
    };

    return (
        <>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.type")}/>
                <DropdownMenu
                    list={TYPE}
                    labelKey="name"
                    placeholder={strings("placeholder.selectType")}
                    selectedValue={type}
                    setSelected={setType}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.name")}/>
                <Input
                    name="name"
                    value={name}
                    setValue={setName}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            {type?.value === "branch" || type?.value === undefined
                ? <>
                    <div className="w-full pb-grid-sm">
                        <Label label={strings("label.address")} />
                        <Textarea
                            name="address"
                            value={address}
                            setValue={setAddress} />
                        <LineMsg type="error" msg={strings("message.genericError")} />
                    </div><div className="flex justify-between gap-grid-xl">
                        <div className="w-full pb-grid-sm">
                            <Label label={strings("label.longitude")} />
                            <Input
                                name="long"
                                value={longitude}
                                setValue={setLongitude} />
                            <LineMsg type="error" msg={strings("message.genericError")} />
                        </div>
                        <div className="w-full pb-grid-sm">
                            <Label label={strings("label.latitude")} />
                            <Input
                                name="lat"
                                value={latitude}
                                setValue={setLatitude} />
                            <LineMsg type="error" msg={strings("message.genericError")} />
                        </div>
                    </div><div className="w-full pb-grid-sm">
                        <Label label={strings("label.country")} />
                        <DropdownMenu
                            list={COUNTY}
                            labelKey="name"
                            placeholder={strings("placeholder.selectCountry")}
                            selectedValue={country}
                            setSelected={setCountry} />
                        <LineMsg type="error" msg={strings("message.genericError")} />
                    </div><div className="w-full pb-grid-sm">
                        <Label label={strings("label.city")} />
                        <DropdownMenu
                            list={CITY}
                            labelKey="name"
                            placeholder={strings("placeholder.selectCity")}
                            selectedValue={city}
                            setSelected={setCity} />
                        <LineMsg type="error" msg={strings("message.genericError")} />
                    </div>
                </>
                : <div className="w-full pb-grid-sm">
                    <Label label={strings("label.url")}/>
                    <Input
                        name="url"
                        value={url}
                        setValue={setURL}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
            }
            <div className="text-end pt-grid-lg">
                <Button
                    text={strings("button.add")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={add}
                />
            </div>
        </>
    );
};

export default AddOutlet;
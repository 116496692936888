import React from "react";

import { motion } from "framer-motion";

import Rank from "./rank";
import CachedImg from "./cachedImg";

import { strings } from "../../util/formatter";
import { Link } from "react-router-dom";

const ChartContestantMedium = (props) =>
{
    const { item, path } = props;

    return (
        <Link to={path}>
            <motion.div
                whileHover={{ scale: 1.025 }}
                className="w-full h-[100px] flex justify-center bg-primary-lightest text-xs sm:text-sm text-center font-primary rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
            >
                <div className="w-[150px] flex flex-col justify-center items-center py-grid-md px-grid-sm md:px-grid-xxl bg-primary-light border-e border-primary-lightest rounded-s-sm">
                    <p className="text-xl">
                        {item?.rank}
                    </p>
                    <Rank rank={item?.rank} rankStatus={item?.rank_status} />
                </div>
                <div className="w-full flex justify-start items-center gap-grid-xs p-grid-xxs sm:p-grid-xs md:p-grid-md">
                    <CachedImg src={item.cover} alt={item?.name} className="h-[50px]" />
                    <p className="pt-grid-xs text-center">
                        {item?.name}
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center gap-grid-xs p-grid-sm md:p-grid-md sm:whitespace-nowrap bg-primary-light border-e border-primary-lightest">
                    <p>
                        {strings("common.weekSales")}
                    </p>
                    <p>
                        {item?.week_sales}
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center gap-grid-xs p-grid-sm md:p-grid-md sm:whitespace-nowrap bg-primary-light border-e border-primary-lightest">
                    <p>
                        {`${strings("common.weeksInPlace")}${item?.rank}`}
                    </p>
                    <p>
                        {item?.weeks_in_place}
                    </p>
                </div>
                <div className="flex flex-col justify-center items-center gap-grid-xs p-grid-sm md:p-grid-md sm:whitespace-nowrap bg-primary-light rounded-e-sm">
                    <p>
                        {strings("common.weeksInCompetition")}
                    </p>
                    <p>
                        {item?.weeks_in_competition}
                    </p>
                </div>
            </motion.div>
        </Link>
    );
};

export default ChartContestantMedium;
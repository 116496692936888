import React, { useContext, useRef } from "react";

import { motion, useScroll, useTransform } from "framer-motion";

import Button from "./button";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";

import landingPage1 from "../../assets/image/landing_page_1_.webp";
import landingPage2 from "../../assets/image/landing_page_2_.webp";
import landingPage3 from "../../assets/image/landing_page_3_.webp";
import landingPage4 from "../../assets/image/landing_page_4_.webp";
import landingPage5 from "../../assets/image/landing_page_5_.webp";
import landingPage6 from "../../assets/image/landing_page_6_.webp";
import landingPage7 from "../../assets/image/landing_page_7_.webp";
import landingPage8 from "../../assets/image/landing_page_8_.webp";
import landingPage9 from "../../assets/image/landing_page_9_.webp";
// import HeatMap from "./heatMap";

const Section1 = () =>
{
    const { prefs, lenis } = useContext(SessionContext);
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start start", "end start"]
    });
    const imagesTranslateX1 = useTransform(scrollYProgress, [0, 0.6], [0, prefs?.dir === "rtl" ? -200 : 200]);
    const imagesTranslateX2 = useTransform(scrollYProgress, [0, 0.6], [0, prefs?.dir === "rtl" ? 200 : -200]);
    const imagesTranslateY1 = useTransform(scrollYProgress, [0, 0.6], [0, 100]);
    const imagesTranslateY2 = useTransform(scrollYProgress, [0, 0.6], [0, -200]);
    const imagesTranslateY3 = useTransform(scrollYProgress, [0, 0.6], [0, 200]);
    const imagesTranslateY4 = useTransform(scrollYProgress, [0, 0.6], [0, 100]);
    const imagesOpacity = useTransform(scrollYProgress, [0.2, 0.5], [1, 0]);

    const scrollToCharts = () =>
    {
        lenis.scrollTo("#third-section", { offset: -93 });
    };

    return (
        <div ref={targetRef} className="h-screen md:h-[135vh]">
            <motion.div className="relative h-screen flex justify-center items-center text-primary-lightest font-primary text-center">
                <motion.div className="w-3/4 md:w-2/3 lg:w-1/2">
                    <p className="text-xxl font-primary mb-grid-xl">
                        {strings("landingPage.firstSectionHead")}
                    </p>
                    <p className="text-xl font-secondary mb-grid-xl px-grid-md">
                        {strings("landingPage.firstSectionSubsidiary")}
                    </p>
                    <Button text={strings("button.mostPopular")} padding="px-grid-xl" optionalStyle="border border-primary-lightest" bgColor="bg-transparent" onClick={scrollToCharts}/>
                </motion.div>
                <motion.img style={{ translateY: imagesTranslateY4, translateX: imagesTranslateX1, opacity: imagesOpacity }} src={landingPage1} width={160} className="hidden md:block absolute md:top-[20%] md:-end-[2%] lg:end-0 z-10"/>
                <motion.img style={{ translateY: imagesTranslateY2, translateX: imagesTranslateX1, opacity: imagesOpacity }} src={landingPage2} width={160} className="hidden md:block absolute md:top-[51.5%] md:end-[4%] lg:end-[8%] z-10"/>
                <motion.img style={{ translateY: imagesTranslateY2, translateX: imagesTranslateX1, opacity: imagesOpacity }} src={landingPage3} width={160} className="hidden md:block absolute md:top-[83%] md:end-[2%] lg:end-[3%] z-10"/>
                <motion.img style={{ translateY: imagesTranslateY3, translateX: imagesTranslateX1, opacity: imagesOpacity }} src={landingPage4} width={160} className="hidden md:block absolute md:top-[95%] lg:top-[92%] md:end-[23%] z-10"/>
                <motion.img style={{ translateY: imagesTranslateY1, opacity: imagesOpacity }} src={landingPage5} width={160} className="hidden md:block absolute md:top-[105%] xl:top-[100%] md:start-[40%] z-10"/>
                <motion.img style={{ translateY: imagesTranslateY3, translateX: imagesTranslateX2, opacity: imagesOpacity }} src={landingPage6} width={160} className="hidden md:block absolute md:top-[92%] lg:top-[77%] md:start-[24%] lg:start-[20%] xl:start-[24%] z-10"/>
                <motion.img style={{ translateY: imagesTranslateY2, translateX: imagesTranslateX2, opacity: imagesOpacity }} src={landingPage7} width={160} className="hidden md:block absolute md:top-[85%] md:start-[7%] lg:end-[7%] z-10"/>
                <motion.img style={{ translateY: imagesTranslateY2, translateX: imagesTranslateX2, opacity: imagesOpacity }} src={landingPage8} width={160} className="hidden md:block absolute md:top-[38%] md:start-[3%] lg:end-[3%] z-10"/>
                <motion.img style={{ translateY: imagesTranslateY4, translateX: imagesTranslateX2, opacity: imagesOpacity }} src={landingPage9} width={160} className="hidden md:block absolute md:top-[3%] lg:top-[10%] md:start-[6%] lg:start-[10%] xl:start-[15%] z-10"/>
            </motion.div>
            {/* <HeatMap libraries={["visualization"]}/> */}
        </div>
    );
};

export default Section1;
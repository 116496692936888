import React, { useContext, useState } from "react";

import Button from "./button";

import { SessionContext } from "../../util/context";
import { getLangCountryURL, strings } from "../../util/formatter";
import { createSearchParams, useNavigate } from "react-router-dom";

const SearchInput = ({ shouldShowShadow = true, theme = "pre-login" }) =>
{
    const navigate = useNavigate();
    const { prefs } = useContext(SessionContext);
    const [searchKey, setSearchKey] = useState("");
    const isPostLogin = theme === "post-login";

    const handleClick = () =>
    {
        if (searchKey)
        {
            navigate({
                pathname: `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/search`,
                search: createSearchParams({
                    q: searchKey
                })?.toString()
            });
        }
    };

    return (
        <div className="relative flex justify-center items-center">
            <input
                value={searchKey}
                placeholder={strings("placeholder.search")}
                onChange={(e) => setSearchKey(e.target.value)}
                className={`w-full px-grid-md ${isPostLogin ? "py-[10.5px] text-sm bg-neutral-high-regular" : "py-grid-sm text-base bg-primary-lightest"} text-secondary-darkest rounded-full focus:outline-none transition-all duration-fast ease-in ${shouldShowShadow ? "shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]" : ""}`}
            />
            <Button
                text={strings("button.searchNow")}
                iconOnly={isPostLogin}
                icon={isPostLogin ? "magnifer" : "arrow"}
                iconWidth={isPostLogin ? 17 : 24}
                iconHeight={isPostLogin ? 16 : 24}
                bgColor={isPostLogin ? "bg-transparent" : "bg-secondary-darkest"}
                font="text-md"
                padding={`py-grid-xs ${isPostLogin ? "px-grid-xs" : "pe-grid-xs ps-grid-sm"}`}
                iconStyle={`${prefs.dir === "rtl" ? "" : "rotate-180"} fill-none`}
                iconStroke={isPostLogin ? "stroke-neutral-low-regular" : "stroke-primary-lightest"}
                optionalStyle="absolute my-grid-sm end-grid-xs flex items-center gap-grid-xs sm:gap-grid-md"
                onClick={handleClick}
            />
        </div>
    );
};

export default SearchInput;
import React, { useContext } from "react";

import SVGIcon from "./svgIcons";
import Button from "./button";

import { SessionContext } from "../../util/context";

const InfoCard = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { item, buttonText, buttonAction } = props;
    return (
        <div className={`min-w-[340px] min-h-[467px] max-w-[340px] max-h-[467px] xl:min-w-[380px] xl:min-h-[450px] xl:max-w-[380px] xl:max-h-[450px] flex flex-col justify-between p-grid-md bg-primary-lightest text-secondary-darkest text-lg rounded-lg shadow-[0_50px_50px_0_rgba(0,0,0,0.1)]`}>
            <div>
                <div className="w-grid-xxl h-grid-xxl flex justify-center items-center bg-primary-light rounded-sm shadow-[0_0_50px_0_rgb(var(--color-primary-light))]">
                    <SVGIcon name={item?.iconName || "shop"} stroke="stroke-secondary-darkest"/>
                </div>
                <p className="py-grid-lg font-primary text-secondary-darkest">
                    {item?.title || "العنوان هنا"}
                </p>
                <p className="py-grid-lg font-secondary text-secondary-darkest">
                    {item?.description || "النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص هنا النص "}
                </p>

            </div>
            <div className="flex justify-end">
                <Button
                    text={buttonText}
                    onClick={buttonAction}
                    icon="arrow"
                    bgColor="bg-secondary-darkest"
                    font="text-md"
                    padding="py-grid-xxs pe-grid-xs ps-grid-sm"
                    iconStyle={`${prefs.dir === "rtl" ? "" : "rotate-180"} fill-none`}
                    optionalStyle="flex items-center gap-grid-md"
                />
            </div>
        </div>
    );
};

export default InfoCard;
import React, { useRef } from "react";

import ChartContestantSmall from "./chartContestantSmall";
import DateRange from "./dateRange";

import { strings } from "../../util/formatter";
import useMediaQuery from "../../hook/useMediaQuery";
import ShowAll from "./showAll";
import meladLogo from "../../assets/image/melad_logo.png";

const Section5 = () =>
{
    const targetRef = useRef(null);
    const isSmall = useMediaQuery("(min-width: 640px) and (max-width: 767px)");
    const isMedium = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
    const isLarge = useMediaQuery("(min-width: 1024px) and (max-width: 1279px)");
    const bookList = [
        {
            id: 1,
            image: meladLogo,
            name: "اسم الناشر",
            rank: 1,
            rank_status: "up"
        },
        {
            id: 2,
            image: meladLogo,
            name: "اسم الناشر",
            rank: 2,
            rank_status: "star"
        },
        {
            id: 3,
            image: meladLogo,
            name: "اسم الناشر",
            rank: 3,
            rank_status: "down"
        },
        {
            id: 4,
            image: meladLogo,
            name: "اسم الناشر مكون من سطرين",
            rank: 4,
            rank_status: "up"
        },
        {
            id: 5,
            image: meladLogo,
            name: "اسم الناشر",
            rank: 5,
            rank_status: "same"
        }
    ];

    return (
        <div ref={targetRef} className={`relative mb-grid-xxl mx-grid-lg md:mx-0 flex flex-col justify-start`}>
            <p className="pb-grid-sm text-xxl font-primary text-primary-lightest">
                {strings("title.charts")}
            </p>
            <div className="flex justify-between items-start">
                <DateRange dateFrom={"2023-08-13"} dateTo={"2023-08-19"} />
                <ShowAll route={""}/>
            </div>
            <div className="mb-[10%] w-full bg-primary-lightest shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)] rounded-md">
                <div className="md:mx-0 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-grid-lg p-grid-lg justify-items-center md:justify-start lg:justify-center">
                    {bookList.slice(0, isLarge ? 4 : isMedium ? 3 : isSmall ? 2 : 5).map((item, index) =>
                        (
                            <ChartContestantSmall
                                key={index}
                                item={item}
                                containerClassName="max-w-[218px] min-w-[218px] h-[310px] flex flex-col justify-between bg-primary-light text-secondary-darkest p-grid-md cursor-pointer rounded-sm"
                                roundedImg={true}
                                size="md"
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Section5;
import React, { useContext } from "react";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";

const FileInput = (props) =>
{
    const { isPostLogin } = useContext(SessionContext);
    const { fileRef, value, setValue, selectFile } = props;

    return (
        <>
            <div onClick={selectFile} className={`w-full py-grid-sm px-grid-sm flex justify-between items-center gap-grid-xxl text-sm ${isPostLogin ? "bg-neutral-high-regular" : "bg-secondary-light"} text-secondary-darkest cursor-pointer rounded-xs`}>
                <div>
                    {value?.name || ""}
                </div>
                <SVGIcon name="upload2" viewBox="0 0 20 13" width={20} height={20} stroke="stroke-secondary-darkest" className="fill-none"/>
            </div>
            <input
                hidden
                ref={fileRef}
                onChange={(e) => setValue(e.target.files[0])}
                type="file"
            />
        </>
    );
};

export default FileInput;
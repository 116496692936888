import React, { useContext } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import { SessionContext } from "../../util/context";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const SliderComponent = (props) =>
{
    const {
        children,
        breakpoints = {
            1: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            344: {
                slidesPerView: 2,
                spaceBetween: 150
            },
            519: {
                slidesPerView: 2,
                spaceBetween: 70
            },
            640: {
                slidesPerView: 3,
                spaceBetween: 100
            },
            944: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 40
            },
            1280: {
                slidesPerView: 5,
                spaceBetween: 0
            },
            1536: {
                slidesPerView: 5,
                spaceBetween: 0
            }
        },
        spaceBetween = 0,
        slidesPerView = 5,
        containerPadding = "ps-grid-xl sm:ps-grid-xl"
    } = props;

    const { prefs } = useContext(SessionContext);

    return (
        <Swiper
            className={containerPadding}
            modules={[Navigation]}
            breakpoints={breakpoints}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            navigation={true}
            dir={prefs?.dir}
        >
            {children}
            <SwiperSlide/>
        </Swiper>
    );
};

export default SliderComponent;
import React from "react";
import DropdownMenu from "./dropdownMenu";

const RemovableItemWithDropdown = (props) =>
{
    const { index, item, placeholder, labelKey, selected, dropdownList = [], listLength, addValue, removeItem } = props;
    return (
        <>
            <div className="flex justify-between items-center p-grid-sm">
                <div className="text-md">{item[labelKey]}</div>
                <div className="flex gap-grid-xs items-center">
                    <DropdownMenu
                        list={dropdownList}
                        labelKey="label"
                        placeholder={placeholder}
                        selectedValue={selected}
                        setSelected={(e) => addValue(e, item)}
                        width="w-[160px]"
                        gap="gap-grid-sm"
                    />
                    <button onClick={() => removeItem(item)} className="m-0 w-[20px] h-[20px] flex justify-center items-center bg-status-danger-regular text-primary-lightest rounded-full">&times;</button>
                </div>
            </div>
            {(index !== listLength - 1) && <div className="h-[1px] bg-primary-light"/>}
        </>
    );
};

export default RemovableItemWithDropdown;
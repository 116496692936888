import React, { useRef } from "react";

import DateRange from "./dateRange";

import { strings } from "../../util/formatter";
import useMediaQuery from "../../hook/useMediaQuery";
import ShowAll from "./showAll";
import ChartContestantSmall from "./chartContestantSmall";

const Section3 = () =>
{
    const targetRef = useRef(null);
    const isSmall = useMediaQuery("(min-width: 640px) and (max-width: 767px)");
    const isMedium = useMediaQuery("(min-width: 768px) and (max-width: 1023px)");
    const isLarge = useMediaQuery("(min-width: 1024px) and (max-width: 1279px)");
    const bookList = [
        {
            id: 1,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            rank: 1,
            rank_status: "up"
        },
        {
            id: 2,
            cover: "https://miblart.com/wp-content/uploads/2020/01/crime-and-mystery-cover-scaled-1.jpeg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            rank: 2,
            rank_status: "star"
        },
        {
            id: 3,
            cover: "https://www.adobe.com/express/create/cover/media_1316972d23c5f2032e101572da69ae4aec3259fed.jpeg?width=400&format=jpeg&optimize=medium",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            rank: 3,
            rank_status: "down"
        },
        {
            id: 4,
            cover: "https://m.media-amazon.com/images/I/410nynxH30L.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            rank: 4,
            rank_status: "up"
        },
        {
            id: 5,
            cover: "https://marketplace.canva.com/EAFaQMYuZbo/1/0/1003w/canva-brown-rusty-mystery-novel-book-cover-hG1QhA7BiBU.jpg",
            name: "اسم كتاب مكون من سطرين",
            contributor_name: "اسم المؤلف",
            rank: 5,
            rank_status: "same"
        }
    ];

    return (
        <div ref={targetRef} className={`relative mb-grid-xxl mx-grid-lg md:mx-0 flex flex-col justify-start`}>
            <p className="pb-grid-sm text-xxl font-primary text-primary-lightest">
                {strings("title.charts")}
            </p>
            <div className="flex justify-between items-start">
                <DateRange dateFrom={"2023-08-13"} dateTo={"2023-08-19"} />
                <ShowAll route={""}/>
            </div>
            <div className="mb-[10%] w-full bg-primary-lightest shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)] rounded-md">
                <div className="md:mx-0 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-grid-lg p-grid-lg justify-items-center md:justify-start lg:justify-center">
                    {bookList.slice(0, isLarge ? 4 : isMedium ? 3 : isSmall ? 2 : 5).map((item, index) =>
                        (
                            <ChartContestantSmall
                                key={index}
                                item={item}
                                containerClassName="max-w-[218px] h-[450px] flex flex-col justify-between bg-primary-light text-secondary-darkest p-grid-md cursor-pointer rounded-sm"
                                imgClassName="w-[218px] h-[270px]"
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Section3;
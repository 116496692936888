import React, { useContext, useState } from "react";

import DropdownMenu from "../view/component/dropdownMenu";
import LineMsg from "../view/component/lineMsg";
import Label from "../view/component/label";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const ENTITY = [
    {
        id: 1,
        name: "شركة ميلاد للنشر و التوزيع"
    },
    {
        id: 2,
        name: "دار رواشن للنشر و التوزيع"
    },
    {
        id: 3,
        name: "دار أثر للنشر و التوزيع"
    },
    {
        id: 4,
        name: "منشورات تكوين"
    }
];

const ShareProduct = () =>
{
    const { setActiveModal } = useContext(SessionContext);
    const [selectedEntity, setSelectedEntity] = useState({});

    const share = () =>
    {
        setActiveModal(undefined);
    };

    return (
        <>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.selectEntityToShare")}/>
                <DropdownMenu
                    list={ENTITY}
                    labelKey="name"
                    placeholder={strings("placeholder.selectEntity")}
                    selectedValue={selectedEntity}
                    setSelected={setSelectedEntity}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="text-end pt-grid-lg">
                <Button
                    text={strings("button.share")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={share}
                />
            </div>
        </>
    );
};

export default ShareProduct;
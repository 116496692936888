import React from "react";

import Section from "../view/component/section";
import Section1 from "../view/component/section1";
import Section2 from "../view/component/section2";
import Section3 from "../view/component/section3";
import Section4 from "../view/component/section4";
import Section5 from "../view/component/section5";
import Section6 from "../view/component/section6";
import Section7 from "../view/component/section7";
import Section8 from "../view/component/section8";
import Section9 from "../view/component/section9";
import Section10 from "../view/component/section10";

const LandingPage = () =>
{
    return (
        <>
            <Section>
                <Section1 />
            </Section>
            <Section optionalStyle="min-h-auto">
                <Section2/>
            </Section>
            <Section id={"third-section"} optionalStyle="min-h-[100vh]">
                <Section3 />
            </Section>
            <Section id={"fourth-section"} optionalStyle="min-h-[100vh]">
                <Section4 />
            </Section>
            <Section id={"fifth-section"} optionalStyle="min-h-[100vh]">
                <Section5 />
            </Section>
            <Section optionalStyle="min-h-screen bg-primary-lightest">
                <Section6 />
            </Section>
            <Section optionalStyle="min-h-[130vh] bg-primary-lightest">
                <Section7 />
            </Section>
            <Section optionalStyle="min-h-screen bg-primary-lightest">
                <Section8 />
            </Section>
            <Section optionalStyle="min-h-screen bg-primary-lightest" useContainer={false}>
                <Section9 />
            </Section>
            <Section optionalStyle="min-h-screen bg-primary-lightest">
                <Section10 />
            </Section>
        </>
    );
};

export default LandingPage;
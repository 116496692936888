import React, { useContext } from "react";
import { SessionContext } from "../../util/context";

const Input = (props) =>
{
    const { isPostLogin } = useContext(SessionContext);
    const { name, value, type = "text", placeholder, setValue } = props;

    return (
        <input
            onChange={(e) => setValue(e.target.value)}
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            className={`w-full py-grid-sm px-grid-sm ${isPostLogin ? "bg-neutral-high-regular" : "bg-secondary-light"} text-secondary-darkest text-sm rounded-xs placeholder:text-secondary-darkest/50 focus:outline-none`}
        />
    );
};

export default Input;
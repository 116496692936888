import React, { useContext, useState } from "react";

import Tabs from "../view/component/tabs";
import ListOfSales from "../view/component/listOfSales";
import Avatar from "../view/component/avatar";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";
import constant from "../config/constant";

export const countries =
[
    {
        name: "السعودية",
        value: "saudi"
    },
    {
        name: "الامارات",
        value: "emirates"
    }
];

const ITEM = {
    id: 1,
    logo: "https://play-lh.googleusercontent.com/sGJ92rD1dBl0Z-ybhp3GAUp8Pj-R3G44WCz4at5Z7kCoS0bPdPQmrbffYCbeBN9tDTM",
    name: "مكتبة جرير",
    commercial_register: "101097537535",
    country: "المملكة العربية السعودية",
    entity: "منشأة كبرى",
    sales_by_branches: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "روبين بلازا",
                link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu",
                sales: 55
            },
            {
                id: 2,
                name: "طريق عثمان بن عفان",
                link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu",
                sales: 55
            }
        ]
    },
    sales_by_region: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "الرياض",
                sales: 55
            },
            {
                id: 2,
                name: "جدة",
                sales: 55
            },
            {
                id: 3,
                name: "الدمام",
                sales: 55
            }
        ]
    },
    sales_by_day: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "الرياض",
                sales: 55
            },
            {
                id: 2,
                name: "جدة",
                sales: 55
            },
            {
                id: 3,
                name: "الدمام",
                sales: 55
            }
        ]
    }
};

const ITEM_EN = {
    id: 1,
    logo: "https://play-lh.googleusercontent.com/sGJ92rD1dBl0Z-ybhp3GAUp8Pj-R3G44WCz4at5Z7kCoS0bPdPQmrbffYCbeBN9tDTM",
    name: "Jarir Bookstore",
    commercial_register: "101097537535",
    country: "Saudi Arabia",
    entity: "Large facility",
    sales_by_branches: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "Robin Plaza",
                link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu",
                sales: 55
            },
            {
                id: 2,
                name: "Othman Ibn Afan Road",
                link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu",
                sales: 55
            }
        ]
    },
    sales_by_region: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "Riyadh",
                sales: 55
            },
            {
                id: 2,
                name: "Jeddah",
                sales: 55
            },
            {
                id: 3,
                name: "Dammam",
                sales: 55
            }
        ]
    },
    sales_by_day: {
        total_sales: 165,
        list: [
            {
                id: 1,
                name: "Riyadh",

                sales: 55
            },
            {
                id: 2,
                name: "Jeddah",
                sales: 55
            },
            {
                id: 3,
                name: "Dammam",
                sales: 55
            }
        ]
    }
};

const SellerModal = () =>
{
    const { prefs } = useContext(SessionContext);
    const [active, setActive] = useState("details");
    const [selected, setSelected] = useState(countries[0]);
    const [date, setDate] = useState(Date.now());

    const BasicContent = (title, item, itemEn) =>
    {
        return (
            <div className="grid grid-flow-col grid-cols-[max-content] gap-grid-xs pt-grid-sm">
                <p className="w-[130px] font-primary">
                    {strings(title)}:
                </p>
                <div>
                    {prefs?.lang === constant.lang.values[0] ? item : itemEn}
                </div>
            </div>
        );
    };

    return (
        <Tabs
            active={active}
            setActive={setActive}
            isModal={true}
            list={[
                { key: "details", label: strings("common.details") },
                { key: "salesByBranch", label: strings("common.salesByBranch") },
                { key: "salesByRegion", label: strings("common.salesByRegion") },
                { key: "salesByDay", label: strings("common.salesByDay") }
            ]}
            shouldShowAll={true}
        >
            {active === "details"
                ? <div className="mx-grid-xl md:mx-auto p-grid-md flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-start justify-between text-secondary-darkest text-sm font-secondary">
                    <div className="w-full md:w-[100px]">
                        <div className="w-auto">
                            <Avatar src={prefs?.lang === constant[0] ? ITEM.logo : ITEM_EN.logo} size="sm"/>
                        </div>
                    </div>
                    <div className="w-full md:w-[66.66%] font-secondary">
                        {BasicContent("common.commercialRegister", ITEM.commercial_register, ITEM_EN.commercial_register)}
                        {BasicContent("common.country", ITEM.country, ITEM_EN.country)}
                        {BasicContent("common.entity", ITEM.entity, ITEM_EN.entity)}
                    </div>
                </div>
                : active === "salesByBranch"
                    ? <ListOfSales
                        list={ITEM?.sales_by_branches?.list}
                        totalSales={ITEM.sales_by_branches?.total_sales}
                        filterType="none"
                    />
                    : active === "salesByRegion"
                        ? <ListOfSales
                            list={ITEM?.sales_by_day?.list}
                            totalSales={ITEM.sales_by_day?.total_sales}
                            filterType="list"
                            filterList={countries}
                            filterValue={selected}
                            setFilterValue={setSelected}
                        />
                        : <ListOfSales
                            list={ITEM?.sales_by_day?.list}
                            totalSales={ITEM.sales_by_day?.total_sales}
                            filterType="date"
                            filterValue={date}
                            setFilterValue={setDate}
                        />
            }
        </Tabs>
    );
};

export default SellerModal;
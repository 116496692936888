import React, { useContext, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import Section from "../view/component/section";
import ChartContestantLarge from "../view/component/chartContestantLarge";
import ChartContestantMedium from "../view/component/chartContestantMedium";
import DateRange from "../view/component/dateRange";

import { SessionContext } from "../util/context";
import { strings, getLangCountryURL } from "../util/formatter";

const Charts = () =>
{
    const { prefs } = useContext(SessionContext);
    const params = useParams();
    const [activeTab, setActiveTab] = useState(params?.type ? params?.type : "product");
    const TOP_LIST = [
        {
            title: "common.product",
            key: "product"
        },
        {
            title: "common.novel",
            key: "novel"
        },
        {
            title: "common.poetry",
            key: "poetry"
        },
        {
            title: "common.contributor",
            key: "contributor"
        },
        {
            title: "common.producer",
            key: "producer"
        }
    ];
    const CHART_ITEMS = [
        {
            id: 1,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 1,
            rank_status: "up"
        },
        {
            id: 2,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "230 ألف",
            rank: 2,
            rank_status: "up"
        },
        {
            id: 3,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب مكون من اكثر من سطر",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "10 آلاف",
            rank: 3,
            rank_status: "down"
        },
        {
            id: 4,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 4,
            rank_status: "down"
        },
        {
            id: 5,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 5,
            rank_status: "up"
        },
        {
            id: 6,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب مكون من اكثر من سطر",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 6,
            rank_status: "up"
        },
        {
            id: 7,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 7,
            rank_status: "up"
        },
        {
            id: 8,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 8,
            rank_status: "up"
        },
        {
            id: 9,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 9,
            rank_status: "up"
        },
        {
            id: 10,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 10,
            rank_status: "up"
        },
        {
            id: 11,
            cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
            name: "اسم الكتاب",
            contributor_name: "اسم المؤلف",
            weeks_in_place: "3",
            weeks_in_competition: "120",
            week_sales: "64.5 ألف",
            rank: 11,
            rank_status: "up"
        }
    ];

    useEffect(() =>
    {
        setActiveTab(params?.type);
    }, [params?.type]);

    return (
        <>
            <Section optionalStyle="min-h-[85vh]">
                <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%] pb-grid-xl px-grid-md md:px-0">
                    <div className="flex gap-grid-sm sm:gap-grid-md lg:gap-grid-lg text-primary-lightest pb-grid-xl">
                        {TOP_LIST.map((item, index) => (
                            <Link
                                key={index}
                                className={`w-[18%] sm:w-[20%] h-[100px] md:h-[150px] lg:h-[170px] flex flex-col justify-center items-center p-grid-xxs lg:p-0 font-primary text-md sm:text-lg lg:text-xl text-center bg-primary-dark border-4 ${activeTab === item.key ? "border-primary-darkest" : "border-primary-dark cursor-pointer"} rounded-sm`}
                                to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/${item.key}`}
                            >
                                <p>
                                    {strings("chartPage.topHundred")}
                                </p>
                                <p>
                                    {strings(`${item.title}`)}
                                </p>
                            </Link>
                        ))}
                    </div>
                    <p className="pt-grid-sm text-xxl font-primary text-primary-lightest">
                        {strings("chartPage.topHundred")}{strings(`common.${activeTab}`)}
                    </p>
                    <DateRange dateFrom={"2023-08-13"} dateTo={"2023-08-19"} />
                </div>
            </Section>
            <Section>
                <div className="relative pb-grid-xl px-grid-md md:px-0">
                    <div className="hidden xl:flex flex-wrap xl:flex-nowrap gap-grid-xxl pb-grid-xxl">
                        {CHART_ITEMS?.slice(0, 3)?.map((item, index) => (
                            <ChartContestantLarge
                                key={index}
                                item={item}
                                path={["product", "novel", "poetry"].includes?.(params?.type)
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                            />
                        ))}
                    </div>
                    <div className="flex xl:hidden flex-col gap-grid-md pt-grid-md pb-grid-md">
                        {CHART_ITEMS?.slice(0, 3)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={["product", "novel", "poetry"].includes?.(params?.type)
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                            />
                        ))}
                    </div>
                    <div className="flex flex-col gap-grid-md">
                        {CHART_ITEMS?.slice(3, CHART_ITEMS?.length)?.map((item, index) => (
                            <ChartContestantMedium
                                key={index}
                                item={item}
                                path={["product", "novel", "poetry"].includes?.(params?.type)
                                    ? `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/product/${item.id}`
                                    : `/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${params?.type}/${item.id}`
                                }
                            />
                        ))}
                    </div>
                </div>
            </Section>
        </>
    );
};

export default Charts;
import React, { useContext, useState } from "react";

import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";

import Section from "../view/component/section";
import Slider from "../view/component/slider";
import ProductCard from "../view/component/productCard";
import RankRow from "../view/component/rankRow";
import CachedImg from "../view/component/cachedImg";
import Tabs from "../view/component/tabs";
import DropdownMenu from "../view/component/dropdownMenu";
import SVGIcon from "../view/component/svgIcons";

import constant from "../config/constant";
import { SessionContext } from "../util/context";
import { getDate, getLangCountryURL, strings } from "../util/formatter";

const Product = () =>
{
    const { prefs } = useContext(SessionContext);
    const [active, setActive] = useState("branches");
    const ITEM = {
        id: 1,
        cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
        name: "اعرف لماذا يغرد الطائر الحبيس",
        des: "بعد علاقة مضطربة، قام الزوجان (بيلي سينيور) و (فيفيان باكستر) بشحن ابنائهما (مايا) و(بيلي جونيور) إلى الجدة في جنوب الولايات المتحدة، تماماً مثلما تُشحن البضائع، ليكتشف الطفلان بعدها أن الحياة في الجنوب لا يمكن أن تكون سوى صورة حقيقة للجحيم، إذ وبخلاف حالة الطقس المتردية، كانت بلدة الجدّة واقعة تحت تأثير الفصل العنصري وما تبقى من أعراف اضطهاد السود، وكما لو أن هذا لم يكن كافياً، جاءت حالة الكساد الكبير لتدفع ذاك المجتمع الجنوبي، وتدفع الطفلين أيضاً، إلى دائرة الهلاك، مما زاد من عدد الحواجز التي توجّب عليهم أن يتجاوزوها.",
        isbn: "798-098-09876",
        rank: {
            place: 5,
            chart: "product"
        },
        contributors: [
            {
                name: "إياد عبدالرحمن (مترجم)",
                id: "Eyad_Abdulrahman"
            },
            {
                name: "مايا آنجلو (مؤلف)",
                id: "Maya_Angelo"
            }
        ],
        producers: [
            {
                name: "دار ميلاد للنشر والتوزيع",
                id: "Melad_Publishing_and_Distribution_House"
            }
        ],
        produced_at: "2023-09-24Z00:00:00",
        languages: [
            "العربية",
            "الانجليزية"
        ],
        pages_count: 382,
        categories: [
            "رواية",
            "رحلات",
            "تاريخ"
        ],
        types: [
            "ورقي"
        ],
        price: "54.99 ريال سعودي",
        publishing_status: "متوفر",
        distribution_status: "متوفر",
        audience_group: [
            "بالغين",
            "أطفال"
        ],
        availability: {
            branches: [
                {
                    id: 1,
                    name: "الرياض",
                    list: [
                        {
                            id: 1,
                            name: "مكتبة جرير",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        },
                        {
                            id: 2,
                            name: "فيرجن ستور",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        }
                    ]
                }
            ],
            online_stores: [
                {
                    id: 1,
                    name: "دار ميلاد للنشر و التوزيع",
                    link: "https://store.darmelad.com"
                },
                {
                    id: 2,
                    name: "مكتبة جرير",
                    link: "https://www.jarir.com/arabic-books-472235.html"
                }
            ]
        },
        related: [
            {
                id: 1,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 3,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 4,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 5,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            }
        ]
    };
    const ITEM_EN = {
        id: 1,
        cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
        name: "Know why the caged bird chirps",
        des: "After a troubled relationship, the couple (Billy Senior) and (Vivienne Baxter) shipped their sons (Maya) and (Billy Jr.) to the grandmother in the southern United States, just as goods are shipped, and the children then discover that life in the South can only be a true picture of hell, as unlike the bad weather, the town of Grandmother was under the influence of apartheid and the remaining customs of black oppression, and as if this were not enough, the Great Depression came to push that Southern society, It also pushes the children into doom, increasing the number of barriers they have had to overcome. ",
        isbn: "798-098-09876",
        rank: {
            place: 5,
            chart: "product"
        },
        contributors: [
            {
                name: "Eyad Abdulrahman (translator)",
                id: "Eyad_Abdulrahman"
            },
            {
                name: "Maya Angelo (author)",
                id: "Maya_Angelo"
            }
        ],
        producers: [
            {
                name: "Melad Publishing and Distribution House",
                id: "Melad_Publishing_and_Distribution_House"
            }
        ],
        produced_at: "2023-09-24Z00:00:00",
        languages: [
            "Arabic",
            "English"
        ],
        pages_count: 382,
        categories: [
            "Novel",
            "Trips",
            "History"
        ],
        types: [
            "Paper"
        ],
        price: "54.99 SAR",
        publishing_status: "Available",
        distribution_status: "Available",
        audience_group: [
            "Adults",
            "Kids"
        ],
        availability: {
            branches: [
                {
                    id: 1,
                    name: "Riyadh",
                    list: [
                        {
                            id: 1,
                            name: "Jarir Bookstore",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        },
                        {
                            id: 2,
                            name: "Vergin Store",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        }
                    ]
                }
            ],
            online_stores: [
                {
                    id: 1,
                    name: "Milad Publishing and Distribution House",
                    link: "https://store.darmelad.com"
                },
                {
                    id: 2,
                    name: "Jarir Bookstore",
                    link: "https://www.jarir.com/arabic-books-472235.html"
                }
            ]
        },
        related: [
            {
                id: 1,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "Book Name",
                contributor_name: "contributor name"
            },
            {
                id: 2,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "Book Name",
                contributor_name: "contributor name"
            },
            {
                id: 3,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "Book Name",
                contributor_name: "contributor name"
            },
            {
                id: 4,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "Book Name",
                contributor_name: "contributor name"
            },
            {
                id: 5,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "Book Name",
                contributor_name: "contributor name"
            }
        ]
    };
    const [selected, setSelected] = useState(ITEM.availability.branches[0]);
    const list = [
        { key: "branches", label: strings("productPage.retailers") },
        { key: "online_stores", label: strings("productPage.onlineStores") }
    ];

    const BasicContentList = (title, list, listEn, isLink = false, id = "", path = "") =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <div className="flex flex-col gap-grid-xs">
                    {list.map((item, index) => (
                        isLink
                            ? <Link key={index} to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/${path}/${item?.[id]}`} className="underline underline-offset-2">
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.name : listEn[index]?.name}
                            </Link>
                            : <p key={index}>
                                {prefs?.lang === constant.lang.values[0] ? list[index]?.name : listEn[index]?.name}
                            </p>
                    ))}
                </div>
            </div>
        );
    };

    const BasicContent = (title, item, itemEn) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <p>
                    {prefs?.lang === constant.lang.values[0] ? item : itemEn}
                </p>
            </div>
        );
    };

    const ContentWithBG = (title, list, listEn) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm">
                <p className="w-[180px] font-primary text-start">
                    {strings(title)}:
                </p>
                <div className="flex flex-1 gap-grid-sm flex-wrap">
                    {list.map((item, index) => (
                        <p key={index} className="px-grid-md py-grid-xxs border-[2px] border-primary-light text-center rounded-xl">
                            {prefs?.lang === constant.lang.values[0] ? list[index] : listEn[index]}
                        </p>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Section useContainer={false}>
            <div className="relative mx-grid-md md:mx-0 pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <p className="px-grid-xl md:px-0 container mx-auto pb-grid-xxl text-xl font-primary text-primary-lightest">
                    {prefs?.lang === constant.lang.values[0] ? ITEM.name : ITEM_EN.name}
                </p>
                <div className="px-grid-xl md:px-0 container mx-auto pb-grid-xl">
                    <RankRow rank={ITEM.rank?.place} path={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/charts/${ITEM.rank?.chart}`}/>
                </div>
                <div className="mx-grid-xl md:mx-auto container mt-grid-xl flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-start justify-between p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                    <div className="w-full md:w-[25%]">
                        <CachedImg src={prefs?.lang === constant.lang.values[0] ? ITEM.cover : ITEM_EN.cover} alt="" className="w-full rounded-sm"/>
                    </div>
                    <div className="w-full md:w-[75%] font-secondary">
                        <p className="leading-normal">
                            {prefs?.lang === constant.lang.values[0] ? ITEM.des : ITEM_EN.des}
                        </p>
                        {BasicContentList("common.theContributor", ITEM.contributors, ITEM_EN.contributors, true, "id", "contributor")}
                        {BasicContentList("common.theProducer", ITEM.producers, ITEM_EN.producers, true, "id", "producer")}
                        {BasicContent("common.producedAt", getDate(prefs?.lang, ITEM.produced_at), getDate(prefs?.lang, ITEM_EN.produced_at))}
                        {BasicContent("common.isbn", ITEM.isbn, ITEM_EN.isbn)}
                        {ContentWithBG("common.languages", ITEM.languages, ITEM_EN.languages)}
                        {BasicContent("common.pagesCount", ITEM.pages_count, ITEM_EN.pages_count)}
                        {ContentWithBG("common.category", ITEM.categories, ITEM_EN.categories)}
                        {ContentWithBG("common.type", ITEM.types, ITEM_EN.types)}
                        {BasicContent("common.price", ITEM.price, ITEM_EN.price)}
                        {BasicContent("productPage.publishingStatus", ITEM.publishing_status, ITEM_EN.publishing_status)}
                        {BasicContent("productPage.distributionStatus", ITEM.distribution_status, ITEM_EN.distribution_status)}
                        {ContentWithBG("productPage.audienceGroup", ITEM.audience_group, ITEM_EN.audience_group)}
                    </div>
                </div>
                <div className="mx-grid-xl md:mx-auto container mt-grid-xxl mb-grid-xxl">
                    <p className="py-grid-xxl text-xxl font-primary text-primary-lightest">
                        {strings(`productPage.salePoints`)}
                    </p>
                    <Tabs active={active} setActive={setActive} list={list}>
                        {active === "branches" &&
                            <>
                                <div className="mx-grid-md md:mx-0 p-grid-lg flex justify-end">
                                    <DropdownMenu
                                        list={ITEM.availability.branches}
                                        labelKey="name"
                                        selectedValue={selected}
                                        setSelected={setSelected}
                                    />
                                </div>
                                <div className="divide-y divide-solid divide-primary-light px-grid-lg pb-grid-lg">
                                    {ITEM.availability?.branches?.filter(branch => branch?.id === selected?.id)?.[0]?.list?.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/seller/${item?.id}`}
                                            className="p-grid-sm flex gap-grid-sm items-center justify-between text-secondary-darkest text-lg cursor-pointer"
                                        >
                                            <div className="flex gap-grid-xs items-center">
                                                {item?.name}
                                            </div>
                                            <div className="flex items-center">
                                                <SVGIcon name="chevron" viewBox="0 0 64 64" width={17} height={17} stroke="stroke-secondary-darkest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </>
                        }
                        {active === "online_stores" &&
                            <div className="divide-y divide-solid divide-primary-light p-grid-lg">
                                {ITEM.availability?.online_stores?.map((item, index) => (
                                    <Link
                                        key={index}
                                        to={item?.link}
                                        target="_blank"
                                        className="p-grid-sm flex gap-grid-sm items-center justify-between text-secondary-darkest text-lg cursor-pointer"
                                    >
                                        <div className="flex gap-grid-xs items-center">
                                            {item?.name}
                                        </div>
                                        <div className="flex items-center">
                                            <SVGIcon name="chevron" viewBox="0 0 64 64" width={17} height={17} stroke="stroke-secondary-darkest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        }
                    </Tabs>
                </div>
                <div className="pt-grid-xxl pb-grid-xxl">
                    <p className="mx-grid-xl md:mx-auto container pb-grid-sm text-xxl font-primary text-primary-lightest">
                        {strings(`productPage.similarPosts`)}
                    </p>
                    <Slider>
                        {ITEM?.related?.map((item, indexOfItem) => (
                            <SwiperSlide key={indexOfItem}>
                                <ProductCard
                                    item={item}
                                    containerClassName="min-w-[218px] min-h-[410px] max-w-[218px] max-h-[410px] mt-grid-xxl mb-[115px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                    imgClassName="w-[218px] h-[270px]"
                                    textClassName="pt-grid-xs text-center"
                                />
                            </SwiperSlide>
                        ))}
                    </Slider>
                </div>
            </div>
        </Section>
    );
};

export default Product;
import React, { useContext } from "react";

import { strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";
import SVGIcon from "./svgIcons";

const ShowAll = () =>
{
    const { prefs } = useContext(SessionContext);
    return (
        <div className="w-fit md:w-auto ms-auto mb-grid-sm md:mb-0 md:ms-0 flex items-center gap-grid-sm text-lg flex-nowrap text-primary-lightest cursor-pointer hover:opacity-60 transition-opacity ease-linear duration-200">
            {strings("common.showAll")}
            <SVGIcon name="chevron" viewBox="0 0 64 64" width={17} height={17} stroke="stroke-primary-lightest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
        </div>
    );
};

export default ShowAll;
import React, { useState } from "react";

import Label from "./label";
import LineMsg from "./lineMsg";

import { strings } from "../../util/formatter";
import SVGIcon from "./svgIcons";

export const apiKey = "CD4565342D46AB34421";

const ManageEntityIntegration = () =>
{
    const [isCopied, setIsCopied] = useState(false);

    const onCopyClick = () =>
    {
        if (!isCopied)
        {
            setIsCopied(true);
            navigator.clipboard.writeText(apiKey);
            setTimeout(() =>
            {
                setIsCopied(false);
            }, 2000);
        }
    };

    return (
        <>
            <p className="text-sm mb-grid-sm">
                {strings("common.keyProtectionMsg")}
            </p>
            <div className="h-[1px] bg-primary-light mt-grid-md mb-grid-xl"/>
            <div className="w-full py-grid-sm">
                <Label label={strings("label.yourEntityAPIKey")}/>
                <div className="flex justify-between p-grid-xs bg-secondary-light rounded-xs">
                    <p className="text-md">{apiKey}</p>
                    <div onClick={onCopyClick} className="cursor-pointer">
                        {isCopied

                            ? <p className="text-sm">{strings("common.copied")}</p>
                            : <SVGIcon name="copy" width={20} height={20} viewBox="0 0 24 24" className="fill-secondary-darkest"/>
                        }

                    </div>
                </div>
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
        </>
    );
};

export default ManageEntityIntegration;
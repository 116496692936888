import React, { useContext, useState } from "react";

import Button from "../view/component/button";
import AddProductStep1 from "../view/component/addProductStep1";
import AddProductStep2 from "../view/component/addProductStep2";
import AddProductStep3 from "../view/component/addProductStep3";
import AddProductStep4 from "../view/component/addProductStep4";
import AddProductStep5 from "../view/component/addProductStep5";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

const AddProductModal = () =>
{
    const { setActiveModal, modalOptions, setModalOptions } = useContext(SessionContext);
    const [productInfo, setProductInfo] = useState({});

    const prev = () =>
    {
        setModalOptions({
            ...modalOptions,
            currentStep: modalOptions.currentStep - 1
        });
    };

    const next = () =>
    {
        if (modalOptions?.totalSteps === modalOptions?.currentStep)
        {
            setActiveModal(undefined);
        }
        else
        {
            setModalOptions({
                ...modalOptions,
                currentStep: modalOptions.currentStep + 1
            });
        }
    };

    return (
        <>
            {modalOptions.currentStep === 1
                ? <AddProductStep1/>
                : modalOptions.currentStep === 2
                    ? <AddProductStep2/>

                    : modalOptions.currentStep === 3
                        ? <AddProductStep3/>

                        : modalOptions.currentStep === 4
                            ? <AddProductStep4/>
                            : <AddProductStep5/>
            }
            <div className={`flex ${modalOptions.currentStep !== 1 ? "justify-between" : "justify-end"} pt-grid-lg`}>
                {modalOptions.currentStep !== 1 &&
                    <Button
                        text={strings("button.prevouis")}
                        bgColor="bg-secondary-darkest"
                        padding="py-2 px-grid-xl"
                        onClick={prev}
                    />
                }
                <Button
                    text={strings("button.next")}
                    bgColor="bg-secondary-darkest"
                    padding="py-2 px-grid-xl"
                    onClick={next}
                />
            </div>
        </>
    );
};

export default AddProductModal;
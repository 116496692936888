import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Trans } from "react-i18next";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";

const RankRow = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { rank, path } = props;

    return (
        <Link to={path}>
            <motion.div whileHover={{ scale: 1.02 }} className="p-grid-sm flex gap-grid-sm items-center justify-between bg-primary-lightest text-secondary-darkest text-lg font-primary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                <div className="flex gap-grid-xs items-center">
                    <SVGIcon name="rank" stroke="stroke-secondary-darkest"/>
                    <Trans
                        i18nKey="common.occupiesPlace"
                        components={{ span: <span className="text-primary-regular" />, p: <p /> }}
                    />
                    <span className="text-primary-regular">{rank}</span>
                    <span>
                        {strings("common.inTopHundred")}
                    </span>
                </div>
                <div className="flex items-center">
                    <SVGIcon name="chevron" viewBox="0 0 64 64" width={17} height={17} stroke="stroke-secondary-darkest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
                </div>
            </motion.div>
        </Link>
    );
};

export default RankRow;
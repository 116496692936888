import React, { useContext, useState } from "react";

import { Link } from "react-router-dom";

import Section from "../view/component/section";
import Avatar from "../view/component/avatar";
import Tabs from "../view/component/tabs";
import SVGIcon from "../view/component/svgIcons";
import DropdownMenu from "../view/component/dropdownMenu";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";
import constant from "../config/constant";

const Seller = () =>
{
    const { prefs } = useContext(SessionContext);
    const [active, setActive] = useState("branches");
    const ITEM = {
        id: 1,
        logo: "https://play-lh.googleusercontent.com/sGJ92rD1dBl0Z-ybhp3GAUp8Pj-R3G44WCz4at5Z7kCoS0bPdPQmrbffYCbeBN9tDTM",
        name: "مكتبة جرير",
        commercial_register: "101097537535",
        country: "المملكة العربية السعودية",
        entity: "منشأة كبرى",
        availability: {
            branches: [
                {
                    id: 1,
                    name: "الرياض",
                    list: [
                        {
                            id: 1,
                            name: "روبين بلازا",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        },
                        {
                            id: 2,
                            name: "طريق عثمان بن عفان",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        }
                    ]
                }
            ],
            online_stores: [
                {
                    name: "مكتبة جرير",
                    link: "https://www.jarir.com/arabic-books-472235.html"
                }
            ]
        }
    };
    const ITEM_EN = {
        id: 1,
        logo: "https://play-lh.googleusercontent.com/sGJ92rD1dBl0Z-ybhp3GAUp8Pj-R3G44WCz4at5Z7kCoS0bPdPQmrbffYCbeBN9tDTM",
        name: "Jarir Bookstore",
        commercial_register: "101097537535",
        country: "Saudi Arabia",
        entity: "Large facility",
        availability: {
            branches: [
                {
                    id: 1,
                    city_name: "Riyadh",
                    list: [
                        {
                            id: 1,
                            name: "Robin Plaza",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        },
                        {
                            id: 2,
                            name: "Othman Ibn Afan Road",
                            link: "https://www.google.com/maps/search/Riyadh+%D8%A7%D9%84%D8%B1%D9%8A%D8%A7%D8%B6+%D9%85%D9%83%D8%AA%D8%A8%D8%A9+%D8%AC%D8%B1%D9%8A%D8%B1%E2%80%AD/@24.6935988,46.6248003,12z/data=!3m1!4b1?entry=ttu"
                        }
                    ]
                }
            ],
            online_stores: [
                {
                    id: 1,
                    name: "Jarir Bookstore",
                    link: "https://www.jarir.com/arabic-books-472235.html"
                }
            ]
        }
    };
    const [selected, setSelected] = useState(ITEM.availability.branches[0]);
    const list = [
        { key: "branches", label: strings("productPage.retailers") },
        { key: "online_stores", label: strings("productPage.onlineStores") }
    ];

    const BasicContent = (title, item, itemEn) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <p>
                    {prefs?.lang === constant.lang.values[0] ? item : itemEn}
                </p>
            </div>
        );
    };

    return (
        <Section useContainer={false}>
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                    {prefs?.lang === constant.lang.values[0] ? ITEM.name : ITEM_EN.name}
                </p>
                <div className="mx-grid-lg md:mx-auto md:container mt-grid-xl flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-start justify-start p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                    <div className="w-auto">
                        <Avatar src={prefs?.lang === constant.lang.values[0] ? ITEM.logo : ITEM_EN.logo} size="lg"/>
                    </div>
                    <div className="w-full md:w-[66.66%] font-secondary">
                        {BasicContent("common.commercialRegister", ITEM.commercial_register, ITEM_EN.commercial_register)}
                        {BasicContent("common.country", ITEM.country, ITEM_EN.country)}
                        {BasicContent("common.entity", ITEM.entity, ITEM_EN.entity)}
                    </div>
                </div>
                <div className="mx-grid-xl md:mx-auto container mt-grid-xxl mb-grid-xxl">
                    <p className="py-grid-xxl text-xxl font-primary text-primary-lightest">
                        {strings(`productPage.salePoints`)}
                    </p>
                    <Tabs active={active} setActive={setActive} list={list}>
                        {active === "branches" &&
                            <>
                                <div className="mx-grid-md md:mx-0 p-grid-lg flex justify-end">
                                    <DropdownMenu
                                        list={ITEM.availability.branches}
                                        labelKey="name"
                                        selectedValue={selected}
                                        placeholder={strings("placeholder.selectProduct")}
                                        setSelected={setSelected}
                                    />
                                </div>
                                <div className="divide-y divide-solid divide-primary-light px-grid-lg pb-grid-lg">
                                    {ITEM.availability?.branches?.filter(branch => branch?.id === selected?.id)?.[0]?.list?.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={item?.link}
                                            target="_blank"
                                            className="p-grid-sm flex gap-grid-sm items-center justify-between text-secondary-darkest text-lg cursor-pointer"
                                        >
                                            <div className="flex gap-grid-xs items-center">
                                                {item?.name}
                                            </div>
                                            <div className="flex items-center">
                                                <SVGIcon name="chevron" viewBox="0 0 64 64" width={17} height={17} stroke="stroke-secondary-darkest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </>
                        }
                        {active === "online_stores" &&
                            <div className="divide-y divide-solid divide-primary-light p-grid-lg">
                                {ITEM.availability?.online_stores?.map((item, index) => (
                                    <Link
                                        key={index}
                                        to={item?.link}
                                        target="_blank"
                                        className="p-grid-sm flex gap-grid-sm items-center justify-between text-secondary-darkest text-lg cursor-pointer"
                                    >
                                        <div className="flex gap-grid-xs items-center">
                                            {item?.name}
                                        </div>
                                        <div className="flex items-center">
                                            <SVGIcon name="chevron" viewBox="0 0 64 64" width={17} height={17} stroke="stroke-secondary-darkest" className={`${prefs?.dir === "rtl" ? "rotate-180" : ""} fill-none`}/>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        }
                    </Tabs>
                </div>
            </div>
        </Section>
    );
};

export default Seller;
const constant =
{
    lang:
    {
        default: "ar",
        values: ["ar", "en"]
    },

    direction:
    {
        default: "rtl",
        alternative: "ltr",
        values: ["rtl", "ltr"]
    },

    currency:
    {
        default: "sar",
        values: ["sar"]
    },

    theme:
    {
        default: "light",
        values: ["light", "dark"]
    },

    header_search_input_path: ["charts", "new-release", "product", "producer", "contributor", "seller", "page"],

    hide_footer_details_path: ["entity"],

    otp:
    {
        channel:
        {
            default: "sms",
            values: ["sms", "email"]
        },
        duration: 120,
        length: 6
    },

    country:
    {
        default: "sa",
        values: ["sa", "bh", "ae", "kw", "qa"]
    },

    mobile_code:
    {
        defualt: "966"
    },

    mobile:
    {
        prefix: "5",
        length: 12
    },

    pagination:
    {
        menu:
        {
            default: 3
        },
        list:
        {
            default: 25
        }
    },

    animation:
    {
        speed:
        {
            slowest: 9000,
            slow: 7000,
            normal: 5000,
            fast: 3000,
            fastest: 1000
        }
    },

    interceptor:
    {
        token: [1017]
    },

    main_page:
    [
        {
            key: "charts",
            path: "charts/product",
            title: "title.charts",
            sortId: 0
        },
        {
            key: "newRelease",
            path: "new-release",
            title: "title.newRelease",
            sortId: 1
        },
        {
            key: "products",
            path: "products",
            title: "title.products",
            sortId: 3
        },
        {
            key: "producers",
            path: "producers",
            title: "title.producers",
            sortId: 4
        },
        {
            key: "distributors",
            path: "distributors",
            title: "title.distributors",
            sortId: 5
        },
        {
            key: "login",
            isModal: true,
            title: "title.login",
            sortId: 6
        },
        {
            key: "langCountry",
            sortId: 7
        }
    ],

    menu_section:
    [
        {
            title: "title.performanceServices",
            sortId: 0,
            items: [
                {
                    key: "overview",
                    path: "overview",
                    title: "title.overview",
                    icon: "home",
                    sortId: 0
                },
                {
                    key: "productPerformance",
                    path: "product-performance",
                    title: "title.productsPerformance",
                    icon: "notebook",
                    sortId: 1
                },
                {
                    key: "contributorPerformance",
                    path: "contributor-performance",
                    title: "title.contributorsPerformance",
                    icon: "user",
                    sortId: 2
                },
                {
                    key: "producerPerformance",
                    path: "producer-performance",
                    title: "title.producersPerformance",
                    icon: "printer",
                    sortId: 3
                },
                {
                    key: "sellerPerformance",
                    path: "seller-performance",
                    title: "title.sellersPerformance",
                    icon: "cart",
                    sortId: 4
                },
                {
                    key: "categoryPerformance",
                    path: "category-performance",
                    title: "title.categoriesPerformance",
                    icon: "star",
                    sortId: 5
                },
                {
                    key: "audiencePerformance",
                    path: "audience-performance",
                    title: "title.audiencePerformance",
                    icon: "folder",
                    sortId: 6
                },
                {
                    key: "cityPerformance",
                    path: "city-performance",
                    title: "title.citiesPerformance",
                    icon: "mapPoint",
                    sortId: 7
                }
            ]
        },
        {
            title: "title.producerServices",
            sortId: 1,
            items: [
                {
                    key: "productManagement",
                    path: "product-management",
                    title: "title.productsManagement",
                    icon: "monitor",
                    sortId: 0
                },
                {
                    key: "myProductsPerformance",
                    path: "my-products",
                    title: "title.myProductsPerformance",
                    icon: "notebook",
                    sortId: 1
                }
            ]
        },
        {
            title: "title.sellerServices",
            sortId: 2,
            items: [
                {
                    key: "outletManagement",
                    path: "outlet-management",
                    title: "title.outletManagement",
                    icon: "shop",
                    sortId: 0
                }
            ]
        }
    ]
};

export default constant;
import React, { useContext, useState } from "react";

import Section from "../view/component/section";
import Avatar from "../view/component/avatar";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";
import constant from "../config/constant";
import ProductCard from "../view/component/productCard";
import Button from "../view/component/button";
import Loader from "../view/component/loader";

const Producer = () =>
{
    const { prefs } = useContext(SessionContext);
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const ITEM = {
        id: 1,
        logo: "https://cdn.salla.sa/RnneE/gjrIBT01nmvWYhwJV9yjTLn8SzmWawTKM5GWT1pv.png",
        name: "دار ميلاد للنشر والتوزيع",
        commercial_register: "101097537535",
        country: "المملكة العربية السعودية",
        languages: [
            "العربية",
            "الانجليزية"
        ],
        categories: [
            "رواية",
            "رحلات",
            "تاريخ"
        ],
        products: [
            {
                id: 1,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 3,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 4,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 5,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 6,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 7,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 8,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 9,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 10,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 11,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 12,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 13,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 14,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 15,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 16,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 17,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 18,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 19,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 20,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            }
        ]
    };
    const ITEM_EN = {
        id: 1,
        logo: "https://cdn.salla.sa/RnneE/gjrIBT01nmvWYhwJV9yjTLn8SzmWawTKM5GWT1pv.png",
        name: "Melad Publishing and Distribution House",
        commercial_register: "101097537535",
        country: "Saudi Arabia",
        languages: [
            "Arabic",
            "English"
        ],
        categories: [
            "Novel",
            "Trips",
            "History"
        ],
        products: [
            {
                id: 1,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 3,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 4,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 5,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 6,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 7,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 8,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 9,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 10,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 11,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 12,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 13,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 14,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 15,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 16,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 17,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 18,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 19,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 20,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            }
        ]
    };
    const [list, setList] = useState(prefs?.lang === "ar" ? ITEM?.products : ITEM_EN?.products);

    const loadMore = () =>
    {
        setIsLoading(true);
        const pageList = [
            {
                id: 1,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 1,
                cover: "https://cdn.salla.sa/RnneE/zL2lTtxxU4pwbpVS6XqqcI5RCEKuUMlQthdJJPUb.jpg",
                name: "إهانة غير ضرورية",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://cdn.salla.sa/RnneE/q605vGuybj2X002IJmCuzJMC4pL5Bt9zCilXIXOy.png",
                name: "اعرف لماذا يغرد الطائر الحبيس",
                contributor_name: "اسم المؤلف"
            }
        ];
        if (list?.length < 30)
        {
            setTimeout(() =>
            {
                setList(list?.concat(pageList));
                setIsLoading(false);
            }, 3000);
        }
        else
        {
            setShouldLoadMore(false);
            setIsLoading(false);
        }
    };

    const BasicContent = (title, item, itemEn) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm">
                <p className="w-[180px] font-primary">
                    {strings(title)}:
                </p>
                <p>
                    {prefs?.lang === constant.lang.values[0] ? item : itemEn}
                </p>
            </div>
        );
    };

    const ContentWithBG = (title, list, listEn) =>
    {
        return (
            <div className="flex items-start justify-start gap-grid-xs pt-grid-sm">
                <p className="w-[180px] font-primary text-start">
                    {strings(title)}:
                </p>
                <div className="flex flex-1 gap-grid-sm flex-wrap">
                    {list.map((item, index) => (
                        <p key={index} className="px-grid-md py-grid-xxs bg-primary-light text-center rounded-xl">
                            {prefs?.lang === constant.lang.values[0] ? list[index] : listEn[index]}
                        </p>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <Section useContainer={false}>
            {isLoading && <Loader />}
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%]">
                <p className="px-grid-lg md:px-0 md:container mx-auto text-xl font-primary text-primary-lightest">
                    {prefs?.lang === constant.lang.values[0] ? ITEM.name : ITEM_EN.name}
                </p>
                <div className="mx-grid-lg md:mx-auto md:container mt-grid-xl flex flex-col md:flex-row gap-grid-sm lg:gap-grid-lg items-start justify-start p-grid-sm lg:p-grid-lg bg-primary-lightest text-secondary-darkest text-lg font-secondary rounded-sm shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]">
                    <div className="w-auto">
                        <Avatar src={prefs?.lang === constant.lang.values[0] ? ITEM.logo : ITEM_EN.logo} size="lg"/>
                    </div>
                    <div className="w-full md:w-[66.66%] font-secondary">
                        {BasicContent("common.commercialRegister", ITEM.commercial_register, ITEM_EN.commercial_register)}
                        {BasicContent("common.country", ITEM.country, ITEM_EN.country)}
                        {ContentWithBG("common.languages", ITEM.languages, ITEM_EN.languages)}
                        {ContentWithBG("common.category", ITEM.categories, ITEM_EN.categories)}
                    </div>
                </div>
                <div className="mx-grid-lg md:mx-auto md:container my-grid-xxl">
                    <p className="py-grid-xxl text-xxl font-primary text-primary-lightest">
                        {strings(`common.editions`)}
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-grid-xl sm:gap-grid-sm md:gap-grid-xl p-grid-lg justify-items-center md:justify-start lg:justify-center">
                        {list?.map((product, index) => (
                            <ProductCard
                                key={index}
                                item={product}
                                containerClassName="min-h-[440px] max-w-[280px] max-h-[440px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                imgClassName="w-[280px] h-[300px]"
                                textClassName="pt-grid-xs text-center"
                            />
                        ))}
                    </div>
                </div>
                {shouldLoadMore &&
                (
                    <div className="text-center my-grid-xxl">
                        <Button
                            text={strings("common.loadMore")}
                            padding="px-grid-xxl py-grid-xxs"
                            bgColor="bg-transparent"
                            optionalStyle="border-2 border-primary-lightest"
                            onClick={loadMore}
                        />
                    </div>
                )}
            </div>
        </Section>
    );
};

export default Producer;
import React, { useContext, useState } from "react";

import Loader from "../view/component/loader";
import Table from "../view/component/table";
import DropdownMenu from "../view/component/dropdownMenu";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";
import Button from "../view/component/button";

export const data = [
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
    { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" }
];

export const FILTER_BY =
[
    {
        label: "الرياض",
        value: "riyadh"
    },
    {
        label: "مكة",
        value: "makkah"
    },
    {
        label: "الشرقية",
        value: "eastern_province"
    }
];

const OutletManagement = () =>
{
    const { setActiveModal, setModalOptions } = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(false);
    const [activeFilter, setActiveFilter] = useState(FILTER_BY[0]);
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [tableData, setTableData] = useState(data);

    const filterBy = (item) =>
    {
        if (activeFilter.value !== item.value)
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setActiveFilter(item);
                setIsLoading(false);
            }, 3000);
        }
    };

    const loadMore = () =>
    {
        setIsLoading(true);
        const pageList = [
            { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
            { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" },
            { id: 1, name: "اسم المركز", region: "الرياض", country: "المملكة العربية السعودية" }
        ];
        if (tableData?.length < 30)
        {
            setTimeout(() =>
            {
                setTableData(tableData?.concat(pageList));
                setIsLoading(false);
            }, 3000);
        }
        else
        {
            setShouldLoadMore(false);
            setIsLoading(false);
        }
    };

    return (
        <div className="p-grid-md">
            {isLoading && <Loader />}
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end gap-grid-md pt-grid-md pb-grid-lg">
                <p className="pb-grid-xs lg:pb-0 text-md md:text-lg font-primary text-neutral-low-dark">
                    {strings("title.outletManagement")}
                </p>
                <div className="flex gap-grid-sm items-center">
                    <DropdownMenu
                        list={FILTER_BY}
                        labelKey="label"
                        placeholder={strings("placeholder.selectEntity")}
                        selectedValue={activeFilter}
                        setSelected={filterBy}
                        dropdownBG="bg-neutral-high-regular"
                    />
                    <Button
                        text={strings("button.saleReportsSubmission")}
                        onClick={() =>
                        {
                            setActiveModal("uploadSales");
                            setModalOptions({
                                title: strings("title.saleReportsSubmission"),
                                containerPadding: "p-0"
                            });
                        }}
                        bgColor="bg-secondary-darkest"
                        font="text-md"
                        padding="py-grid-xs px-grid-sm"
                        optionalStyle="text-center"
                    />
                    <Button
                        text={strings("button.addWithSymbol")}
                        onClick={() =>
                        {
                            setActiveModal("addOutlet");
                            setModalOptions({
                                title: strings("title.addOutlet")
                            });
                        }}
                        bgColor="bg-secondary-darkest"
                        font="text-md"
                        padding="py-grid-xs px-grid-sm"
                        optionalStyle="text-center"
                    />
                </div>
            </div>
            <Table
                titles={tableData?.length > 0 ? Object.keys(tableData[0])?.filter(title => !["id"]?.includes(title)) : []}
                tableData={tableData?.filter(title => !["id"]?.includes(title))}
                shouldLoadMore={shouldLoadMore}
                loadMore={loadMore}
            />
        </div>
    );
};

export default OutletManagement;
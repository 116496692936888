import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";

import InfoCard from "./infoCard";

import { getLangCountryURL, strings } from "../../util/formatter";
import { SessionContext } from "../../util/context";

import landingPageShape8 from "../../assets/image/landing_page_shape_8.webp";
import landingPageShape1 from "../../assets/image/landing_page_shape_1.webp";
import landingPageShape2 from "../../assets/image/landing_page_shape_2.webp";

const Section10 = () =>
{
    const { prefs } = useContext(SessionContext);
    const navigate = useNavigate();
    const cards = [
        {
            iconName: "pieChart",
            title: strings("landingPage.eighthSectionFirstCardTitle"),
            description: strings("landingPage.eighthSectionFirstCardDescription"),
            withShadow: false
        },
        {
            iconName: "hammer",
            title: strings("landingPage.eighthSectionSecondCardTitle"),
            description: strings("landingPage.eighthSectionSecondCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.eighthSectionThirdCardTitle"),
            description: strings("landingPage.eighthSectionThirdCardDescription"),
            withShadow: false
        },
        {
            iconName: "pieChart",
            title: strings("landingPage.eighthSectionFirstCardTitle"),
            description: strings("landingPage.eighthSectionFirstCardDescription"),
            withShadow: false
        },
        {
            iconName: "hammer",
            title: strings("landingPage.eighthSectionSecondCardTitle"),
            description: strings("landingPage.eighthSectionSecondCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.eighthSectionThirdCardTitle"),
            description: strings("landingPage.eighthSectionThirdCardDescription"),
            withShadow: false
        },
        {
            iconName: "hammer",
            title: strings("landingPage.eighthSectionSecondCardTitle"),
            description: strings("landingPage.eighthSectionSecondCardDescription"),
            withShadow: false
        },
        {
            iconName: "lock",
            title: strings("landingPage.eighthSectionThirdCardTitle"),
            description: strings("landingPage.eighthSectionThirdCardDescription"),
            withShadow: false
        }
    ];

    return (
        <div className="xl:flex xl:justify-between">
            <div className="h-auto relative min-w-full max-w-full xl:min-w-[31%] xl:max-w-[31%]">
                <div className="top-[35%] lg:sticky">
                    <div className="md:pe-grid-sm text-[50px] font-primary text-secondary-darkest text-center md:text-start">
                        <Trans
                            i18nKey="landingPage.tenthSectionHead"
                            components={{ span: <span className="text-primary-regular" />, p: <p /> }}
                        />
                    </div>
                    <img src={landingPageShape8} className="w-[49%] z-10 mx-auto blur-[2px]"/>
                </div>
            </div>
            <div className="hidden md:flex w-full gap-grid-lg justify-center xl:justify-end xl:overflow-y-hidden pe-10 pb-16">
                <div className="flex flex-col gap-grid-lg">
                    {cards.map((item, index) => (
                        index % 2 === 0 &&
                            <InfoCard
                                key={index}
                                item={item}
                                buttonText={strings("button.readMore")}
                                buttonAction={() => navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/page/about`)}
                            />
                    ))}
                </div>
                <div className="relative pt-[370px] flex flex-col gap-grid-lg">
                    <img src={landingPageShape1} className="absolute top-[250px] z-0 start-grid-xl"/>
                    <img src={landingPageShape2} className="absolute top-grid-xl z-0 end-grid-xl"/>
                    {cards.map((item, index) => (
                        index % 2 > 0 &&
                            <InfoCard
                                key={index}
                                item={item}
                                buttonText={strings("button.readMore")}
                                buttonAction={() => navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/page/about`)}
                            />
                    ))}
                </div>
            </div>
            <div className="flex md:hidden w-full gap-grid-lg justify-center overflow-y-hidden pb-10">
                <div className="flex flex-col gap-grid-lg">
                    {cards.map((item, index) => (
                        <InfoCard
                            key={index}
                            item={item}
                            buttonText={strings("button.readMore")}
                            buttonAction={() => navigate(`/${getLangCountryURL(prefs?.lang, prefs?.country_code)}/page/about`)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Section10;
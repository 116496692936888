import React, { useContext, useState } from "react";

import Loader from "../view/component/loader";
import Table from "../view/component/table";
import Button from "../view/component/button";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

export const data = [
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
    { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" }
];

const ProductManagement = () =>
{
    const { setActiveModal, setModalOptions } = useContext(SessionContext);
    const [isLoading, setIsLoading] = useState(false);
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [tableData, setTableData] = useState(data);

    const loadMore = () =>
    {
        setIsLoading(true);
        const pageList = [
            { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
            { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" },
            { id: 1, standardNumber: 9876622152, title: "اسم الكتاب", price: 25.23, producedAt: "2023-3-4" }
        ];
        if (tableData?.length < 30)
        {
            setTimeout(() =>
            {
                setTableData(tableData?.concat(pageList));
                setIsLoading(false);
            }, 3000);
        }
        else
        {
            setShouldLoadMore(false);
            setIsLoading(false);
        }
    };

    return (
        <div className="p-grid-md">
            {isLoading && <Loader />}
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end gap-grid-md pt-grid-md pb-grid-lg">
                <p className="pb-grid-xs lg:pb-0 text-md md:text-lg font-primary text-neutral-low-dark">
                    {strings("title.productsManagement")}
                </p>
                <div className="flex gap-grid-xs">
                    <Button
                        text={strings("button.shareProducts")}
                        onClick={() =>
                        {
                            setActiveModal("shareProduct");
                            setModalOptions({
                                title: strings("title.shareProducts")
                            });
                        }}
                        bgColor="bg-secondary-darkest"
                        font="text-md"
                        padding="py-grid-xs px-grid-sm"
                        optionalStyle="text-center"
                    />
                    <Button
                        text={strings("button.addWithSymbol")}
                        onClick={() =>
                        {
                            setActiveModal("addProduct");
                            setModalOptions({
                                showStepsBar: true,
                                currentStep: 1,
                                totalSteps: 5,
                                title: strings("title.addProduct")
                            });
                        }}
                        bgColor="bg-secondary-darkest"
                        font="text-md"
                        padding="py-grid-xs px-grid-sm"
                        optionalStyle="text-center"
                    />
                </div>
            </div>
            <Table
                titles={tableData?.length > 0 ? Object.keys(tableData[0])?.filter(title => !["id"]?.includes(title)) : []}
                tableData={tableData?.filter(title => !["id"]?.includes(title))}
                columnType={
                    {
                        date: ["producedAt"],
                        price: ["price"]
                    }
                }
                shouldLoadMore={shouldLoadMore}
                loadMore={loadMore}
            />
        </div>
    );
};

export default ProductManagement;
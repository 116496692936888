import React, { useContext, useState } from "react";

import Label from "../view/component/label";
import LineMsg from "../view/component/lineMsg";
import Input from "../view/component/input";
import Button from "../view/component/button";
import Tabs from "../view/component/tabs";
import ManageEntityDetails from "../view/component/manageEntityDetails";
import ManageEntityUsers from "../view/component/manageEntityUsers";
import ManageEntitySubscriptions from "../view/component/manageEntitySubscriptions";
import ManageEntityIntegration from "../view/component/manageEntityIntegration";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";

const ENTITY = {
    users: [
        {
            id: 1,
            email: "eyad@darmelad.com",
            type: {
                label: "مشرف",
                value: "admin"
            }
        },
        {
            id: 2,
            email: "bassam@darmelad.com",
            type: {
                label: "مشرف",
                value: "admin"
            }
        },
        {
            id: 3,
            email: "abdulaziz@darmelad.com",
            type: {
                label: "مراقب",
                value: "watcher"
            }
        }
    ]
};

export const LANGUAGE = [
    {
        label: "العربية",
        value: "ar"
    },
    {
        label: "الإنجليزية",
        value: "en"
    }
];

const ManageEntity = () =>
{
    const { setActiveModal, setModalOptions } = useContext(SessionContext);
    const [active, setActive] = useState("details");
    const [entity, setEntity] = useState(ENTITY);
    const [email, setEmail] = useState("");

    const save = () =>
    {
        setActiveModal(undefined);
    };

    const invite = () =>
    {
        console.log("invite");
    };

    const changePaymentMethod = () =>
    {
        console.log("changePaymentMethod");
    };

    const updateSubscriptions = () =>
    {
        console.log("updateSubscriptions");
    };

    const changeAPIKey = () =>
    {
        console.log("changeAPIKey");
    };

    return (
        <>
            <Tabs
                active={active}
                setActive={setActive}
                isModal={true}
                list={[
                    { key: "details", label: strings("common.details") },
                    { key: "users", label: `${strings("common.users")} (${entity?.users?.length || 0})` },
                    { key: "subscriptions", label: strings("common.subscriptionsAndPayment") },
                    { key: "integration", label: strings("common.integration") }
                ]}
                shouldShowAll={false}
            >
                <div className="p-grid-md text-secondary-darkest font-secondary">
                    {active === "details"
                        ? <ManageEntityDetails />
                        : active === "users"
                            ? <ManageEntityUsers entity={entity} setEntity={setEntity}/>
                            : active === "subscriptions"
                                ? <ManageEntitySubscriptions />
                                : <ManageEntityIntegration />
                    }
                </div>
            </Tabs>
            <div className={`flex ${active === "integration" ? "justify-end" : "justify-between items-center"} pt-grid-lg mx-grid-md pb-grid-md ${(active === "users" && entity?.users?.length !== 0) && "border-t border-primary-light"}`}>
                {active === "details"
                    ? <>
                        <p className="text-sm cursor-pointer" onClick={() =>
                        {
                            setActiveModal("disableEntityAccount");
                            setModalOptions({
                                title: strings("common.manageEntity")
                            });
                        }}>
                            {strings("button.disableEntityAccount")}
                        </p>
                    </>
                    : active === "users"
                        ? <div className="w-full">
                            <Label label={strings("label.inviteSomeoneToJoin")}/>
                            <div className="flex justify-between items-center gap-grid-xs">
                                <div className="w-3/4">
                                    <Input
                                        name="email"
                                        placeholder={strings("placeholder.email")}
                                        value={email}
                                        setValue={setEmail}
                                    />
                                </div>
                                <Button
                                    text={strings("button.invite")}
                                    bgColor="bg-secondary-darkest"
                                    padding="py-2 px-grid-xl"
                                    onClick={invite}
                                />
                            </div>
                            <LineMsg type="error" msg={strings("message.genericError")}/>
                        </div>
                        : active === "subscriptions"
                            ? <>
                                <Button
                                    text={strings("button.changePaymentMethod")}
                                    bgColor="bg-secondary-darkest"
                                    padding="py-2 px-grid-xl"
                                    onClick={changePaymentMethod}
                                />
                            </>
                            : <></>
                }
                {active !== "users" &&
                    <Button
                        text={strings(`button.${active === "details" ? "save" : active === "subscriptions" ? "updateSubscriptions" : "changeAPIKey"}`)}
                        bgColor="bg-secondary-darkest"
                        padding="py-2 px-grid-xl"
                        onClick={active === "details" ? save : active === "subscriptions" ? updateSubscriptions : changeAPIKey}
                    />
                }
            </div>
        </>
    );
};

export default ManageEntity;
import React, { useEffect, useRef, useState } from "react";

import { AnimatePresence, easeInOut, motion } from "framer-motion";

import CachedImg from "./cachedImg";
import SVGIcon from "./svgIcons";

import { strings } from "../../util/formatter";

const CountryLangDropdown = (props) =>
{
    const {
        list,
        outLabelKey,
        inLabelKey,
        valueKey,
        imgKey,
        nestedList,
        nestedListLabelKey,
        nestedListValueKey,
        xAxis = "end-0",
        selectedValue,
        setSelected
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const target = useRef(null);

    useEffect(() =>
    {
        document.addEventListener("click", clickOutside, true);
        return () =>
        {
            document.removeEventListener("click", clickOutside, true);
        };
    }, []);

    const open = () =>
    {
        setIsOpen(true);
    };

    const select = (item, firstItem, secondItem) =>
    {
        setSelected(item, firstItem, secondItem);
        setIsOpen(false);
    };

    const clickOutside = (e) =>
    {
        if (!target.current?.contains(e.target)) setIsOpen(false);
    };

    return (
        <div className="relative">
            <div onClick={open} className={`w-full py-2 px-4 flex gap-grid-lg justify-between items-center rounded-md border-[2px] border-primary-lightest text-primary-lightest cursor-pointer`}>
                <div className="flex gap-grid-xs items-center">
                    <CachedImg src={selectedValue?.[imgKey]} alt className="w-[20px]"/>
                    <p className="hidden xl:block">{selectedValue?.[outLabelKey]}</p>
                </div>
                <SVGIcon name="chevron" viewBox="0 0 64 64" width={20} height={20} stroke="stroke-primary-lightest" className="rotate-90 fill-none"/>
            </div>
            <AnimatePresence>
                {isOpen &&
                    <motion.div
                        ref={target}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3, ease: easeInOut }}
                        className={`absolute z-10 top-12 ${xAxis} w-[300px] h-auto rounded-xs drop-shadow bg-primary-lightest divide-y divide-solid divide-primary-light`}
                    >
                        <div className="flex flex-col justify-between">
                            <ul data-lenis-prevent className="max-h-[200px] min-h-[200px] overflow-scroll rounded-t-xs divide-y divide-solid divide-primary-light">
                                {list?.filter((item) => item?.[inLabelKey]?.includes(searchKey))?.map((item, index) =>
                                    (
                                        <div key={index} className="p-grid-sm flex justify-start items-start gap-grid-xs text-start text-secondary-darkest cursor-pointer hover:bg-secondary-light hover:transition-colors hover:duration-fast hover:ease-linear">
                                            <div>
                                                <CachedImg src={item?.[imgKey]} alt="" className="w-[20px]"/>
                                            </div>
                                            <div>
                                                {item?.[inLabelKey]}
                                                <div className="flex gap-grid-xs" key={index}>
                                                    {item?.[nestedList]?.map((nestedItem, index) => (
                                                        <div key={index} className="underline underline-offset-1 hover:opacity-60 text-sm" onClick={() => select(item, item?.[valueKey], nestedItem?.[nestedListValueKey])}>
                                                            {nestedItem?.[nestedListLabelKey]}
                                                        </div>
                                                    )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </ul>
                            <div className="bg-secondary-light p-grid-xxs rounded-b-xs">
                                <input
                                    onChange={(e) => setSearchKey(e.target.value)}
                                    value={searchKey}
                                    placeholder={strings("placeholder.dropdownSearch")}
                                    className="w-full focus:outline-none bg-primary-lightest text-secondary-regular rounded-xs p-2 placeholder:text-secondary-regular/50"
                                />
                            </div>
                        </div>

                    </motion.div>
                }
            </AnimatePresence>
        </div>
    );
};

export default CountryLangDropdown;
import React, { useContext, useEffect } from "react";
import { motion, easeInOut, AnimatePresence } from "framer-motion";

import MODAL from "../../config/modal";

import { SessionContext } from "../../util/context";
import SVGIcon from "./svgIcons";

const Modal = () =>
{
    const { lenis, activeModal, setActiveModal, modalOptions, isPostLogin } = useContext(SessionContext);

    useEffect(() =>
    {
        if (activeModal !== undefined)
        {
            lenis.stop();
        }
        else
        {
            lenis.start();
        };
    }, [activeModal]);

    return (
        <AnimatePresence>
            {activeModal !== undefined && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.6, ease: easeInOut }}
                    data-lenis-prevent
                    className={`w-full fixed inset-0 z-50 py-grid-lg flex justify-center items-center backdrop-blur-md overflow-y-auto`}
                >
                    <motion.div
                        initial={{ opacity: 0, translateY: -2000 }}
                        animate={{ opacity: 1, translateY: 0 }}
                        exit={{ opacity: 0, translateY: 2000 }}
                        transition={{ duration: 0.7, ease: easeInOut }}
                        className="w-[360px] sm:w-[420px] md:w-[700px] m-auto drop-shadow"
                    >
                        <div className="px-grid-lg py-grid-md bg-primary-regular text-primary-lightest text-lg rounded-t-lg shadow-md">
                            <div className="flex justify-between">
                                <div className="font-primary">{modalOptions.title}</div>
                                <button onClick={() => setActiveModal(undefined)} className="m-0 pb-1 w-[32px] h-[32px] border-2 border-primary-lightest rounded-full">&times;</button>
                            </div>
                            {modalOptions?.showStepsBar &&
                                <div className="relative flex justify-between mt-grid-md">
                                    {Array(modalOptions?.totalSteps)?.fill()?.map((_, index) => (
                                        <div
                                            key={index}
                                            className={`relative z-10 h-[32px] w-[32px] flex justify-center items-center bg-primary-darkest text-primary-lightest text-sm font-primary rounded-full`}
                                        >
                                            <AnimatePresence>
                                                {index < modalOptions?.currentStep - 1
                                                    ? <motion.div
                                                        key={index}
                                                        initial={{ opacity: 0.5, scale: 2.5 }}
                                                        animate={{ opacity: 1, scale: 1 }}
                                                        exit={{ opacity: 0, scale: 0.5 }}
                                                        transition={{ duration: 0.7, ease: easeInOut }}
                                                        className="z-10"
                                                    >
                                                        <SVGIcon name="checkMark" viewBox="-9 0 32 11" width={32} height={32} className={`fill-primary-lightest`}/>
                                                    </motion.div>
                                                    : index === modalOptions?.currentStep - 1
                                                        ? `${index + 1}`
                                                        : <motion.div
                                                            key={index}
                                                            className="z-10 h-[22px] w-[22px] flex justify-center items-center bg-primary-lightest text-primary-darkest rounded-full"
                                                            initial={{ scale: 0 }}
                                                            animate={{ scale: 1 }}
                                                            exit={{ scale: 0 }}
                                                            transition={{ duration: 0.7, ease: easeInOut }}
                                                        >
                                                            {index + 1}
                                                        </motion.div>
                                                }
                                            </AnimatePresence>
                                            {index === modalOptions?.currentStep - 1
                                                ? <motion.div
                                                    key={index}
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    transition={{ delay: 0.5, ease: easeInOut }}
                                                    className="absolute z-0 flex justify-center items-center"
                                                >
                                                    {index + 1}
                                                </motion.div>
                                                : null}
                                        </div>
                                    ))}
                                    <div className={`z-0 absolute right-1 top-[calc(50%-2.5px)] h-[5px] w-[calc(100%-6px)] bg-primary-darkest`} />
                                </div>
                            }
                        </div>
                        <div className={`${modalOptions?.containerPadding ? modalOptions?.containerPadding : "px-grid-lg py-grid-md"} font-secondary ${isPostLogin ? "bg-neutral-high-lightest" : "bg-secondary-lightest"} text-secondary-darkest rounded-b-lg shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]`}>
                            {MODAL[activeModal]}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Modal;
import React, { useContext } from "react";
import {
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";

import Layout from "./view/layout/layout.js";
import PostLoginLayout from "./view/layout/postLoginLayout.js";
import Modal from "./view/component/modal.js";
import LandingPage from "./controller/landingPage.js";
import NewRelease from "./controller/newRelease.js";
import Entity from "./controller/entity.js";
import Charts from "./controller/charts.js";
import Product from "./controller/product.js";
import Contributor from "./controller/contributor.js";
import Producer from "./controller/producer.js";
import Seller from "./controller/seller.js";
import StaticPage from "./controller/staticPage.js";
import Dashboard from "./controller/dashboard.js";
import ProductPerformance from "./controller/productPerformance.js";
import ContributorPerformance from "./controller/contributorPerformance.js";
import ProducerPerformance from "./controller/producerPerformance.js";
import SellerPerformance from "./controller/sellerPerformance.js";
import CategoryPerformance from "./controller/categoryPerformance.js";
import AudiencePerformance from "./controller/audiencePerformance.js";
import CityPerformance from "./controller/cityPerformance.js";
import ProductManagement from "./controller/productManagement.js";
import OutletManagement from "./controller/outletManagement.js";
import MyProducerPerformance from "./controller/myProducerPerformance.js";

import { SessionContext } from "./util/context.js";

const App = () =>
{
    const location = useLocation();
    const { prefs, isPostLogin } = useContext(SessionContext);

    return (
        <>
            <ReactNotifications />
            <Routes location={location}>
                <Route path={"/"} element={isPostLogin ? <PostLoginLayout/> : <Layout />}>
                    <Route path={`${prefs?.lang}-${prefs?.country_code}`}>
                        {(window.location.origin !== "https://mofahres.com" || window.location.origin !== "https://qa.mofahres.com" || window.location.origin !== "https://preprod.mofahres.com") &&
                            <>
                                <Route index element={<LandingPage />} />
                                <Route path={"new-release"} element={<NewRelease />} />
                                <Route path={"entity"} element={<Entity />} />
                                <Route path={"charts/:type"} element={<Charts />} />
                                <Route path={"product/:id"} element={<Product />} />
                                <Route path={"producer/:id"} element={<Producer />} />
                                <Route path={"contributor/:id"} element={<Contributor />} />
                                <Route path={"seller/:id"} element={<Seller />} />
                                <Route path={"page/:id"} element={<StaticPage />} />
                                {true &&
                                    <Route path={"dashboard"}>
                                        <Route index element={<Dashboard />} />
                                        <Route path={"overview"} element={<Dashboard />} />
                                        <Route path={"product-performance"} element={<ProductPerformance />} />
                                        <Route path={"contributor-performance"} element={<ContributorPerformance />} />
                                        <Route path={"producer-performance"} element={<ProducerPerformance />} />
                                        <Route path={"seller-performance"} element={<SellerPerformance />} />
                                        <Route path={"category-performance"} element={<CategoryPerformance />} />
                                        <Route path={"audience-performance"} element={<AudiencePerformance />} />
                                        <Route path={"city-performance"} element={<CityPerformance />} />
                                        <Route path={"product-management"} element={<ProductManagement />} />
                                        <Route path={"my-products"} element={<MyProducerPerformance />} />
                                        <Route path={"outlet-management"} element={<OutletManagement />} />
                                    </Route>
                                }
                            </>
                        }
                    </Route>
                </Route>
            </Routes>
            <Modal/>
        </>
    );
};

export default App;
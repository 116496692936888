import React, { useContext } from "react";

import { SwiperSlide } from "swiper/react";

import Section from "../view/component/section";
import Slider from "../view/component/slider";
import ProductCard from "../view/component/productCard";

import { SessionContext } from "../util/context";
import { strings } from "../util/formatter";
import useMediaQuery from "../hook/useMediaQuery";

const NewlyReleased = () =>
{
    const { prefs } = useContext(SessionContext);
    const is2XL = useMediaQuery("(min-width: 1513px)");
    const NEWLY_ITEMS = {
        novels: [
            {
                id: 1,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 3,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 4,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            }
        ],
        poetry: [
            {
                id: 1,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 2,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 3,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 4,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 5,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 6,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 7,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 8,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 9,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 10,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 11,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 12,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "اسم الكتاب",
                contributor_name: "اسم المؤلف"
            },
            {
                id: 13,
                cover: "https://edit.org/images/cat/book-covers-big-2019101610.jpg",
                name: "last itemsssss2",
                contributor_name: "اسم المؤلف"
            }
        ]
    };

    return (
        <Section useContainer={false}>
            <div className="relative pt-[60%] sm:pt-[40%] md:pt-[35%] lg:pt-[20%] pb-grid-xl md:px-0">
                {Object.keys(NEWLY_ITEMS)?.map((key, index) => (
                    <div key={index} dir={prefs?.dir}>
                        <p className="px-grid-xl sm:px-grid-xxl pb-grid-sm text-xxl font-primary text-primary-lightest">
                            {strings(`common.${key}`)}
                        </p>
                        {is2XL
                            ? <div className="flex px-grid-xxl gap-grid-xxl">
                                {NEWLY_ITEMS[key]?.slice(0, 5)?.map((item, indexOfItem) => (
                                    <ProductCard
                                        key={indexOfItem}
                                        item={item}
                                        containerClassName="min-w-[218px] min-h-[410px] max-w-[218px] max-h-[410px] mt-grid-xxl mb-[115px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                        imgClassName="w-[218px] h-[270px]"
                                        textClassName="pt-grid-xs text-center"
                                    />
                                ))}
                            </div>
                            : <Slider>
                                {NEWLY_ITEMS[key]?.map((item, indexOfItem) => (
                                    <SwiperSlide key={indexOfItem}>
                                        <ProductCard
                                            item={item}
                                            containerClassName="min-w-[218px] min-h-[410px] max-w-[218px] max-h-[410px] mt-grid-xxl mb-[115px] p-grid-lg flex flex-col justify-between items-center bg-primary-lightest rounded-sm cursor-pointer shadow-[0_40px_40px_0_rgba(0,0,0,0.3)] md:shadow-[0_50px_50px_0_rgba(0,0,0,0.3)]"
                                            imgClassName="w-[218px] h-[270px]"
                                            textClassName="pt-grid-xs text-center"
                                        />
                                    </SwiperSlide>
                                ))}
                            </Slider>
                        }
                    </div>
                ))}
            </div>
        </Section>
    );
};

export default NewlyReleased;
/* eslint-disable no-constant-condition */
import React, { useContext, useLayoutEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { HelmetProvider, Helmet } from "react-helmet-async";

import PostLoginHeader from "../component/postLoginHeader";
import SubMenu from "../component/subMenu";

import { SessionContext } from "../../util/context";

const PostLoginLayout = () =>
{
    const { prefs } = useContext(SessionContext);
    const location = useLocation();

    useLayoutEffect(() =>
    {
        if (document.getElementById("postLoginInnerContainer"))
        {
            document.getElementById("postLoginInnerContainer").scrollTo(0, 0);
        }
    }, [location.pathname]);

    return (
        <div className="w-full transition-colors duration-middle ease-in font-secondary">
            <HelmetProvider>
                <Helmet>
                    <html lang={prefs?.lang} data-theme={prefs?.theme} dir={prefs?.dir} className="transition-colors duration-middle ease-in"/>
                </Helmet>
                {(window.location.origin === "https://mofahres.com" || window.location.origin === "https://qa.mofahres.com" || window.location.origin === "https://preprod.mofahres.com")
                    ? <div className="w-full h-screen flex justify-center items-center">
                        <p dir="rtl" className="text-xxl font-primary text-primary-lightest">
                            قريبا! ...
                        </p>
                    </div>
                    : <div className="flex relative box-border p-grid-xs md:p-grid-sm">
                        <SubMenu />
                        <div id="postLoginInnerContainer" data-lenis-prevent className="h-[calc(100vh-16px)] md:h-[calc(100vh-32px)] w-full relative bg-neutral-high-lightest rounded-md lg:rounded-e-md lg:rounded-s-none overflow-y-scroll overflow-x-clip">
                            <PostLoginHeader />
                            <div className="pt-[80px]">
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                }
            </HelmetProvider>
        </div>
    );
};

export default PostLoginLayout;
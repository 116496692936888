import React, { useContext, useEffect, useState } from "react";

import Label from "../view/component/label";

import { SessionContext } from "../util/context";
import { strings, secToMinSec } from "../util/formatter";
import OTPInputComponent from "../view/component/otpInput";

const OTP = (props) =>
{
    const context = useContext(SessionContext);
    const { modalOptions } = context;
    const { email } = props;
    const [otp, setOTP] = useState("");
    const [timer, setTimer] = useState(10);
    const userEmail = modalOptions?.email ? modalOptions?.email : email;

    useEffect(() =>
    {
        if (otp?.length === 6)
        {
            modalOptions?.onVerify?.();
        }
    }, [otp]);

    useEffect(() =>
    {
        const interval = setInterval(() =>
        {
            if (timer <= 0)
            {
                clearInterval(interval);
            }
            else
            {
                setTimer(timer - 1);
            }
        }, 1000);
        return () =>
        {
            clearInterval(interval);
        };
    }, [timer]);

    const resendOTP = () =>
    {
        setTimer(10);
    };

    const OTPCounter = () =>
    {
        return (
            <div className="text-lg text-center">
                {secToMinSec(timer) === "00:00"
                    ? <button className="underline underline-offset-1 cursor-pointer" onClick={resendOTP}>{strings("button.getNewOTP")}</button>
                    : <p>{strings("common.otpExpiresIn")} <span className="text-primary-regular font-primary">{secToMinSec(timer)}</span></p>
                }
            </div>
        );
    };

    return (
        <>
            <div className="text-center pt-grid-md">
                <Label label={
                    <p>{strings("label.otp")} <span className="text-primary-regular font-primary">{userEmail}</span></p>
                }/>
                <div dir="ltr" className="pt-grid-md pb-grid-xs">
                    <OTPInputComponent
                        autoFocus
                        isNumberInput={true}
                        length={6}
                        className="flex flex-nowrap gap-grid-sm justify-center items-center"
                        inputClassName="w-[2.5rem] sm:w-[3rem] md:w-[3.5rem] py-grid-xs md:py-grid-sm rounded-xs bg-secondary-light text-secondary-darkest placeholder:text-secondary-darkest/50 focus:outline-secondary-darkest focus:outline-1 text-center"
                        onChangeOTP={(otp) => setOTP(otp)}
                    />
                </div>
            </div>
            <div className="py-grid-lg">
                <OTPCounter />
            </div>
        </>
    );
};

export default OTP;
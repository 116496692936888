import React from "react";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const LineChartView = (props) =>
{
    const { data, labelKey, valueKey } = props;
    return (
        <ResponsiveContainer width="100%" height={250}>
            <LineChart
                data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={labelKey} />
                <YAxis dataKey={valueKey} tick={<CustomizedYAxisTick/>} />
                <Tooltip content={<CustomTooltip value={valueKey}/>} />
                <Line type="monotone" dataKey={valueKey} stroke="#273245" />
            </LineChart>
        </ResponsiveContainer>
    );
};

const CustomizedYAxisTick = ({ x, y, payload }) =>
{
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0}
                textAnchor="start"
                fill="#666">{new Intl.NumberFormat().format(payload?.value)}</text>
        </g>
    );
};

const CustomTooltip = ({ active, payload, label, value }) =>
{
    if (active && payload && payload.length)
    {
        return (
            <div className="color-background-bg p-1" style={{ border: "1px solid #000" }}>
                <p className="label">{`${label} (${payload[0]?.payload?.[value]})`}</p>
            </div>
        );
    }
};

export default LineChartView;
import React from "react";
import Avatar from "./avatar";

const UserInfo = (props) =>
{
    const { src = "https://media.istockphoto.com/id/1300972574/photo/millennial-male-team-leader-organize-virtual-workshop-with-employees-online.jpg?s=170667a&w=0&k=20&c=S9AVbcsSpY1e6vwbnrrZHJzlAtnuSQKtmk11fDItSHE=", size = "sm", displayName, countryName, isRow = true } = props;
    return (
        <div className={`inline-flex gap-5 ${!isRow && "flex-col"}`}>
            <Avatar src={src} size={size}/>
            <div className={`flex flex-col gap-4 justify-center ${!isRow && "items-center"} text-primary-500`}>
                <p>{displayName}</p>
                {countryName && <p>{countryName}</p>}
            </div>
        </div>
    );
};

export default UserInfo;
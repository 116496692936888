import React from "react";

const DataCardPercentage = (props) =>
{
    const { number, label, description } = props;

    return (
        <div className={`p-grid-sm flex flex-col justify-center items-start text-start bg-neutral-high-regular rounded-sm`}>
            <p className="text-xl text-primary-regular font-primary pb-grid-sm">
                {number}
            </p>
            <p className="text-md text-neutral-low-dark font-primary pb-grid-xs">
                {label}
            </p>
            <p className="text-xs text-neutral-low-dark">
                {description}
            </p>
        </div>
    );
};

export default DataCardPercentage;
import React, { useContext, useEffect, useRef, useState } from "react";

import { AnimatePresence, easeInOut, motion } from "framer-motion";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";

import CachedImg from "./cachedImg";

const UserDropdown = (props) =>
{
    const { isPostLogin } = useContext(SessionContext);
    const {
        list,
        outLabelKey,
        secondOutLabelKey,
        inLabelKey,
        imgKey,
        xAxis = "end-0",
        setSelected
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const target = useRef(null);

    useEffect(() =>
    {
        document.addEventListener("click", clickOutside, true);
        return () =>
        {
            document.removeEventListener("click", clickOutside, true);
        };
    }, []);

    const open = () =>
    {
        setIsOpen(true);
    };

    const select = (item) =>
    {
        setSelected(item);
        setIsOpen(false);
    };

    const clickOutside = (e) =>
    {
        if (!target.current?.contains(e.target)) setIsOpen(false);
    };

    return (
        <div className="relative">
            <div onClick={open} className={`w-full py-[5px] ps-grid-xs pe-grid-sm flex justify-between items-center bg-neutral-high-regular text-neutral-low-dark  rounded-md cursor-pointer`}>
                <div className="flex gap-grid-xs items-center">
                    {imgKey && <CachedImg src={imgKey} alt className="w-[28px] rounded-full"/>}
                    <div className="flex flex-col">
                        <p className="font-primary text-sm">
                            {outLabelKey}
                        </p>
                        <p className="text-xs text-neutral-low-regular">
                            {secondOutLabelKey}
                        </p>
                    </div>
                </div>
                <SVGIcon name="chevron" viewBox="0 0 64 64" width={20} height={20} stroke="stroke-neutral-low-regular" className="rotate-90 fill-none"/>
            </div>
            <AnimatePresence>
                {isOpen &&
                    <motion.div
                        ref={target}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3, ease: easeInOut }}
                        className={`absolute z-10 top-12 ${xAxis} w-[300px] h-auto rounded-xs drop-shadow ${isPostLogin ? "bg-neutral-high-lightest" : "bg-secondary-lightest"}`}
                    >
                        <ul data-lenis-prevent className="max-h-[203px] overflow-scroll rounded-xs divide-y divide-solid divide-primary-light">
                            {list?.map((item, index) =>
                                (
                                    <div
                                        key={index}
                                        className={`px-grid-xs py-grid-sm flex justify-start items-start gap-grid-xs text-sm text-start text-secondary-darkest cursor-pointer ${isPostLogin ? "hover:bg-neutral-high-regular" : "hover:bg-secondary-light"} hover:transition-colors hover:duration-fast hover:ease-linear`}
                                        onClick={() => select(item)}
                                    >
                                        {item?.[inLabelKey]}
                                    </div>
                                ))}
                        </ul>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    );
};

export default UserDropdown;
import React, { useContext, useEffect, useRef, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import SVGIcon from "./svgIcons";

import { SessionContext } from "../../util/context";
import { strings } from "../../util/formatter";

const DropdownMenu = (props) =>
{
    const { isPostLogin } = useContext(SessionContext);
    const {
        list,
        labelKey,
        selectedValue,
        placeholder,
        setSelected,
        xAxis = "end-0",
        shadow = "",
        dropdownBG = isPostLogin ? "bg-neutral-high-regular" : "bg-secondary-light",
        width = "w-full",
        gap = "gap-grid-xxl"
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const target = useRef(null);

    useEffect(() =>
    {
        document.addEventListener("click", clickOutside, true);
        return () =>
        {
            document.removeEventListener("click", clickOutside, true);
        };
    }, []);

    const open = () =>
    {
        setIsOpen(true);
    };

    const select = (item) =>
    {
        setSelected(item);
        setIsOpen(false);
    };

    const clickOutside = (e) =>
    {
        if (!target.current?.contains(e.target)) setIsOpen(false);
    };

    return (
        <div className="relative">
            <div onClick={open} className={`${width} py-grid-sm px-grid-sm flex justify-between items-center ${gap} text-sm ${dropdownBG} text-secondary-darkest cursor-pointer rounded-xs ${shadow}`}>
                <div className="flex gap-grid-xs items-center">
                    <div className={`${!selectedValue?.[labelKey] && "text-secondary-darkest"}`}>
                        {selectedValue?.[labelKey] || placeholder}
                    </div>
                </div>
                <SVGIcon name="chevron" viewBox="0 0 58 58" width={14} height={14} stroke="stroke-secondary-darkest" className="rotate-90 fill-none"/>
            </div>
            <AnimatePresence>
                {isOpen &&
                <motion.div
                    ref={target}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                    className={`absolute z-10 top-14 ${xAxis} min-w-[300px] w-full h-auto rounded-xs drop-shadow ${isPostLogin ? "bg-neutral-high-lightest" : "bg-secondary-lightest"} divide-y divide-solid divide-primary-light`}
                >
                    <div className="flex flex-col justify-between">
                        <ul data-lenis-prevent className="max-h-[200px] min-h-[200px] overflow-scroll rounded-t-xs divide-y divide-solid divide-primary-light">
                            {list?.filter(item => item?.[labelKey]?.includes(searchKey))?.map((item, index) =>
                                (
                                    <div
                                        key={index}
                                        className={`px-grid-xs py-grid-sm text-md text-secondary-darkest cursor-pointer ${isPostLogin ? "hover:bg-neutral-high-regular" : "hover:bg-secondary-light"} hover:transition-colors hover:duration-fast hover:ease-linear`}
                                        onClick={() => select(item)}
                                    >
                                        {item?.[labelKey]}
                                    </div>
                                ))}
                        </ul>
                        <div className="bg-secondary-light p-grid-xxs rounded-b-xs">
                            <input
                                onChange={(e) => setSearchKey(e.target.value)}
                                value={searchKey}
                                placeholder={strings("placeholder.dropdownSearch")}
                                className={`w-full focus:outline-none ${isPostLogin ? "bg-neutral-high-lightest" : "bg-secondary-lightest"} text-secondary-regular rounded-xs p-2 placeholder:text-secondary-regular/50`}
                            />
                        </div>
                    </div>
                </motion.div>}
            </AnimatePresence>
        </div>
    );
};

export default DropdownMenu;
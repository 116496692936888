import React, { useState } from "react";

import Loader from "../view/component/loader";
import Table from "../view/component/table";
import DropdownMenu from "../view/component/dropdownMenu";

import { strings } from "../util/formatter";

export const data = [
    { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
    { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" }
];

export const FILTER_BY =
[
    {
        label: strings("dashboard.thisDay"),
        value: "day"
    },
    {
        label: strings("dashboard.thisWeek"),
        value: "week"
    },
    {
        label: strings("dashboard.thisMonth"),
        value: "month"
    },
    {
        label: strings("dashboard.thisQuarter"),
        value: "quarter"
    },
    {
        label: strings("dashboard.thisYear"),
        value: "year"
    }
];

const AudiencePerformance = () =>
{
    const [isLoading, setIsLoading] = useState(false);
    const [activeFilter, setActiveFilter] = useState(FILTER_BY[0]);
    const [shouldLoadMore, setShouldLoadMore] = useState(true);
    const [tableData, setTableData] = useState(data);

    const filterBy = (item) =>
    {
        if (activeFilter.value !== item.value)
        {
            setIsLoading(true);
            setTimeout(() =>
            {
                setActiveFilter(item);
                setIsLoading(false);
            }, 3000);
        }
    };

    const loadMore = () =>
    {
        setIsLoading(true);
        const pageList = [
            { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
            { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" },
            { id: 1, currentPosition: 1, name: "الاسم", totalSales: 25, prevouisPosition: "23,234", durationInCurrentPosition: "8 أسابيع", durationInCompetition: "6 أسابيع" }
        ];
        if (tableData?.length < 30)
        {
            setTimeout(() =>
            {
                setTableData(tableData?.concat(pageList));
                setIsLoading(false);
            }, 3000);
        }
        else
        {
            setShouldLoadMore(false);
            setIsLoading(false);
        }
    };

    return (
        <div className="p-grid-md">
            {isLoading && <Loader />}
            <div className="flex flex-col lg:flex-row justify-between items-start lg:items-end gap-grid-md pt-grid-md pb-grid-lg">
                <p className="pb-grid-xs lg:pb-0 text-md md:text-lg font-primary text-neutral-low-dark">
                    {strings("title.audiencePerformance")}
                </p>
                <DropdownMenu
                    list={FILTER_BY}
                    labelKey="label"
                    placeholder={strings("placeholder.selectEntity")}
                    selectedValue={activeFilter}
                    setSelected={filterBy}
                    dropdownBG="bg-neutral-high-regular"
                />
            </div>
            <Table
                titles={tableData?.length > 0 ? Object.keys(tableData[0])?.filter(title => !["id"]?.includes(title)) : []}
                tableData={tableData?.filter(title => !["id"]?.includes(title))}
                columnType={{ clickable: [] }}
                shouldLoadMore={shouldLoadMore}
                loadMore={loadMore}
            />
        </div>
    );
};

export default AudiencePerformance;
import React, { useState } from "react";

import LineMsg from "./lineMsg";
import Label from "./label";
import Input from "./input";
import DropdownMenu from "./dropdownMenu";

import { strings } from "../../util/formatter";

export const PRODUCT_TYPE = [
    {
        label: "كتاب إلكتروني",
        value: "ebook"
    },
    {
        label: "كتاب ورقي",
        value: "paper_book"
    }
];

export const AUDIENCE_GROUP = [
    {
        label: "أطفال",
        value: "kids"
    },
    {
        label: "بالغين",
        value: "adults"
    }
];

const AddProductStep2 = () =>
{
    const [ISBN, setISBN] = useState("");
    const [productType, setProductType] = useState({});
    const [audienceGroup, setAudienceGroup] = useState({});

    return (
        <>
            <div className="w-full pb-grid-sm">
                <Label label={strings("label.isbn")}/>
                <Input
                    name="ISBN"
                    value={ISBN}
                    setValue={setISBN}
                />
                <LineMsg type="error" msg={strings("message.genericError")}/>
            </div>
            <div className="flex justify-between gap-grid-xl">
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.productType")}/>
                    <DropdownMenu
                        list={PRODUCT_TYPE}
                        labelKey="label"
                        placeholder={strings("placeholder.selectProductType")}
                        selectedValue={productType}
                        setSelected={setProductType}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
                <div className="w-full pb-grid-sm">
                    <Label label={strings("label.audienceGroup")}/>
                    <DropdownMenu
                        list={AUDIENCE_GROUP}
                        labelKey="label"
                        placeholder={strings("placeholder.selectAudienceGroup")}
                        selectedValue={audienceGroup}
                        setSelected={setAudienceGroup}
                    />
                    <LineMsg type="error" msg={strings("message.genericError")}/>
                </div>
            </div>
        </>
    );
};

export default AddProductStep2;
import React, { useContext } from "react";

import Button from "./button";

import { SessionContext } from "../../util/context";
import { getDate, strings } from "../../util/formatter";

const Table = (props) =>
{
    const { prefs } = useContext(SessionContext);
    const { titles, tableData, columnType, onClickKeys = {}, shouldLoadMore, loadMore } = props;
    return (
        <>
            <div className="overflow-x-auto">
                <table className="min-w-full">
                    <thead>
                        <tr className="font-secondary text-md">
                            {titles.map((title, index) => (
                                <th key={index} className={`py-grid-sm px-grid-xs border-b-4 border-b-neutral-high-lightest bg-neutral-high-dark text-sm font-light text-center text-neutral-low-darkest ${index === 0 ? "rounded-s-xs" : index === titles.length - 1 ? "rounded-e-xs" : ""}`}>
                                    {strings(`common.${title}`)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                className="bg-neutral-high-regular"
                            >
                                {titles.map((title, colIndex) =>
                                {
                                    return (
                                        <td key={colIndex} className={`py-grid-sm px-grid-xs text-sm text-center ${rowIndex !== tableData.length - 1 && "border-b-4 border-b-neutral-high-lightest"} ${colIndex === 0 ? "rounded-s-xs" : colIndex === titles.length - 1 ? "rounded-e-xs" : ""}`}>
                                            <span
                                                className={`${columnType?.clickable?.includes(title) && "underline underline-offset-1 cursor-pointer text-neutral-low-darkest"}`}
                                                // eslint-disable-next-line brace-style
                                                onClick={onClickKeys[title] === undefined ? () => {} : () => onClickKeys[title](row)}
                                            >
                                                {columnType?.date?.includes(title)
                                                    ? getDate(prefs?.lang, row[title])
                                                    : columnType?.price?.includes(title)
                                                        ? `${row[title]} ريال سعودي`
                                                        : row[title]
                                                }
                                            </span>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {shouldLoadMore &&
                (
                    <div className="text-center mt-grid-xxl mb-grid-sm">
                        <Button
                            text={strings("common.loadMore")}
                            padding="px-grid-xxl py-grid-xs"
                            bgColor="bg-secondary-darkest"
                            optionalStyle="border-2 border-primary-lightest"
                            font="text-md"
                            onClick={loadMore}
                        />
                    </div>
                )}
        </>
    );
};

export default Table;